<template>
  <div class="collabo-overlay card">
    <!--<div class="card-header p-2">
      <p class="font-weight-bold mb-0">Aktive Nutzer:</p>
    </div>-->

    <div v-if="showHistory" class="card-header px-2 pt-1 pb-2" @click="showHistory = false">
      <ul v-if="shoutOuts.length" class="list-unstyled small mb-0">
        <li v-for="(shoutOut, index) in shoutOuts" :key="index" class="user">
          <img v-if="user.logo" :src="user.logo" alt="" class="user-indicator logo">
          <span v-else :style="'background:' + getColor(shoutOut.userId)" class="user-indicator"></span>
          <span class="user-name">
            {{ shoutOut.text }}
          </span>
        </li>
      </ul>
      <span v-else class="small text-muted">Keine Nachrichten...</span>
    </div>

    <div v-if="connected" class="card-body px-2 pt-1 pb-2">
      <ul v-if="users && users.length" class="list-unstyled mb-2" @click="showHistory = !showHistory">
        <li v-for="(user, index) in users"
            :key="index"
            :class="ping === user._id ? 'pinged' : ''"
            class="user"
        >
          <img v-if="user.logo" :src="user.logo" alt="" class="user-indicator logo">
          <span v-else :style="'background:' + getColor(user.id)" class="user-indicator"></span>
          <span class="user-name">
            {{ user.nickname }} {{ ping === user._id ? '!' : '' }}
          </span>
          <span class="small">
            {{ user.route }}
          </span>
          <span v-if="shoutOut && shoutOut.userId === user._id" :style="'background:' + getColor(user.id)" class="shout-out">
            {{ shoutOut.text }}
          </span>
        </li>
      </ul>
      <div class="form-row">
        <div class="col">
          <input class="form-control form-control-sm" placeholder="Shout out..." type="text" v-on:keydown.enter="sendMessage($event)">
        </div>
        <div class="col-auto">
          <button class="btn btn-dark btn-sm btn-block" @click="sendPing">Ping</button>
        </div>
      </div>
      <div class="mute-button text-muted">
        <i @click="toggleMute" class="fad" :class="mute ? 'fa-volume-mute' : 'fa-volume'"></i>
      </div>
    </div>
    <div v-else class="card-body not-connected rounded bg-light p-2">
      <p class="mb-0">Reconnecting...</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollabOverlay',
  components: {},
  directives: {},
  props: {},
  data() {
    return {
      showHistory: false,
      shoutOutAudio: require('@/assets/click.mp3'),
      pingAudio: require('@/assets/ping.mp3'),
    }
  },
  computed: {
    mute() {
      return this.user.settings.chatMute;
    },
    connected() {
      return this.socket && this.socket.isConnected;
    },
    collab() {
      return this.socket.activeUsers || {
        users: [],
        shoutOuts: []
      }
    },
    users() {
      return this.collab.users;
    },
    shoutOuts() {
      return this.collab.shoutOuts;
    },
    shoutOut() {
      return this.socket.shoutOut;
    },
    ping() {
      return this.socket.ping;
    },
  },
  watch: {
    '$route'(to) {
      if (this.connected) {
        let route = to.path.split("/")[1];
        this.project.notifications[route] = "";
        this.$store.dispatch('socket/sendMessage', {action: 'routeChange', route: to.path});
      }
    },
    shoutOut(newVal) {
      if (newVal.text !== '' && !this.mute) {
        let audio = new Audio(this.shoutOutAudio);
        audio.play()
      }
    },
    ping(newVal) {
      if (newVal && !this.mute) {
        let audio = new Audio(this.pingAudio);
        audio.play()
      }
    }
  },
  methods: {
    toggleMute() {
      this.user.settings.chatMute = !this.mute;
      this.$store.dispatch('user/updateUser', this.user);
    },
    sendPing() {
      this.$store.dispatch('socket/sendMessage', {action: 'ping'});
    },
    sendMessage($event) {
      this.$store.dispatch('socket/sendMessage', {action: 'shoutOut', text: $event.target.value});
      $event.target.value = "";
    },
    getColor(userId) {
      let color = '#505050';
      let user = this.socket.activeUsers.users.find(u => u._id === (userId ? userId.toString() : userId));
      if (user && user.settings) {
        color = user.settings.chatColor
      }
      return color;
    }
  },
  sockets: {
    message(data) {
      console.debug('socket message', data);
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  updated() {
  }
}
</script>

<style lang="scss" scoped>
.collabo-overlay {
  position: fixed;
  bottom: .5rem;
  right: .5rem;
  box-shadow: 0 1rem 1rem 0 rgba(0, 0, 0, .2);

  .form-control {
    width: 7rem;
  }

  .card-body {
    position: relative;
  }
  .mute-button {
    position: absolute;
    top: .1rem;
    right: .3rem;
  }

  .user {
    position: relative;

    &.pinged {
      font-weight: bold;
      color: var(--pink);
    }

    .user-indicator.logo {
      $size: 24px;
      width: $size;
      height: $size;
    }

    .user-name {
      vertical-align: middle;
    }

    .small {
      font-size: 6px;
    }

    .shout-out {
      position: absolute;
      right: calc(100% + 1rem);
      top: -.05rem;
      padding: .1rem .3rem;
      width: auto;
      white-space: nowrap;
      z-index: 5002;
      background: var(--dark);
      color: white;
      border-radius: 4px;
      box-shadow: 0 1rem 1rem 0 rgba(0, 0, 0, .2);

      &::after {
        height: .5rem;
        width: .5rem;
        position: absolute;
        top: calc(50% - .25rem);
        right: -.5rem;
        transform: rotate(45deg);
      }
    }
  }
}
</style>