<template>
  <div id="login-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead mb-5">
      {{ activeRoute.description }}
    </p>

    <form onsubmit="return false" class="row justify-content-center">
      <div class="col-sm-10 col-md-8 col-lg-5">
        <h3 class="text-center">Willkommen</h3>
        <hr>
        <p>
          Noch keinen Account?
          <router-link to="/signup">Jetzt registrieren.</router-link>
        </p>

        <div class="form-group">
          <label for="email">E-Mail:</label>
          <input id="email"
                 type="email"
                 v-model="data.email"
                 class="form-control"
                 placeholder="deine@email.de"
                 autocomplete="username"
                 required
          >
        </div>

        <div class="form-group">
          <label for="password">Passwort:</label>
          <input id="password"
                 type="password"
                 v-model="data.password"
                 class="form-control"
                 placeholder="Dein P4ssw0rt"
                 autocomplete="current-password"
                 required
          >
        </div>


        <div v-if="loading" class="row align-items-center my-4">
          <div class="col-auto text-center p-0">
            <i class="fad fa-circle-notch fa-spin fa-2x text-primary ml-4"></i>
          </div>
          <div class="col">
            <p class="text-left mb-1">
              <strong>Vielen Dank.</strong><br>
              Du wirst eingeloggt.
            </p>
          </div>
        </div>
        <div v-else-if="failMsg && !success" class="row align-items-center my-4">
          <div class="col-auto text-center p-0">
            <i class="fad fa-times-circle fa-2x text-danger ml-4"></i>
          </div>
          <div class="col">
            <p class="text-left mb-1">
              <strong>Sorry.</strong><br>
              <span v-html="failMsg"></span>
            </p>
          </div>
        </div>
        <div v-else-if="success" class="row align-items-center my-4">
          <div class="col-auto text-center p-0">
            <i class="fad fa-check-circle fa-2x text-success ml-4"></i>
          </div>
          <div class="col">
            <p class="text-left mb-1">
              <strong>Super.</strong><br>
              Du wirst jeden Moment weitergeleitet.
            </p>
          </div>
        </div>

        <button v-if="!loading && !success" class="btn btn-primary btn-block mt-3 mb-5" @click="attemptLogin">Login</button>
      </div>
    </form>

  </div>
</template>

<script>

export default {
  name: 'Login',
  components: {},
  data() {
    return {
      data: {
        email: "",
        password: "",
      },
      loading: false,
      failMsg: "",
      success: false,
    }
  },
  computed: {
    profile() {
      return this.project.profile;
    }
  },
  watch: {
    data() {
      this.failMsg = "";
    }
  },
  methods: {
    attemptLogin() {
      if (!this.data.email || !this.data.password) {
        this.failMsg = "Deine Daten sind nicht vollständig.";
        return;
      }
      this.loading = true;
      this.$store.dispatch('auth/logIn', this.data)
          .then(res => {
            console.debug(res);
            this.loading = false;
            this.success = true;
            setTimeout(() => {
              this.$router.push("/dashboard");
            }, 1000);
          })
          .catch(err => {
            console.debug(err);
            this.loading = false;
            this.success = false;
            if (err.msg && err.msg.response.status === 404) {
              this.failMsg = "Account nicht gefunden. <a href='/signup'>Jetzt Account erstellen</a>";
            } else {
              this.failMsg = "Ein Fehler ist aufgetreten";
            }
          });
    }
  },
  mounted() {
    this.$store.commit('user/removeUserData');
    this.$store.commit('project/removeProjectData');
    this.$store.commit('project/removeScrapeData');
  }
}
</script>
