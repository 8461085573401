<template>
  <div id="overview-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <div v-for="(group, index) in routes.filter(r => !r.hidden)" class="row mb-3" :key="'g' + index">
      <div class="col-12">
        <div class="card text-center">
          <div class="card-body">
            <h3>{{ group.title }}</h3>
            <p class="mb-0">
              {{ group.description }}
            </p>
          </div>
          <div class="card-body">
            <div class="row justify-content-center">
              <div v-for="(route, index) in group.links" class="col-sm-6 col-md-3" :key="'r' + index">
                <div class="flip-card">
                  <div class="card text-center clickable">
                    <div class="card-body front">
                      <h4 class="h5">
                        <i class="fad fa-fw" :class="route.icon"></i> {{ route.title }}
                      </h4>
                    </div>
                    <router-link :to="route.route" tag="div" class="card-body back">
                      <p class="mb-0">
                        {{ route.description }}
                      </p>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Overview',
  components: {},
  data() {
    return {}
  },
  computed: {},
  methods: {},
  beforeMount() {
  }
}
</script>

<style lang="scss" scoped>

.flip-card {
  perspective: 100vw;

  &:hover .card {
    transform: scale(1.05) rotateY(180deg);
  }

  .card.clickable {
    position: relative;
    width: 100%;
    height: 9rem;
    text-align: center;
    transition: all 0.5s;
    transform-style: preserve-3d;
    background-color: var(--primary);
    color: var(--white);

    .card-body {
      position: absolute;
      width: 100%;
      height: 100%;
      transition: all .5s;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      .fad {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }
      &.front {
        background: rgba(var(--primary), .3);
      }
      &.back {
        background: var(--white);
        color: var(--dark);
        border: 5px solid var(--primary);
        border-radius: 5px;
        margin-right: 2px;
        transform: rotateY(180deg);
      }
    }
  }
}

.tool-panel {
  position: relative;
  padding: .5rem;

  .panel {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5rem;
    width: 100%;
    background: var(--primary);
    color: white;
    transition: all .2s;

    &:hover {
      transform: scale(1.05);
      background: var(--primary-dark);
      color: white;
      font-size: 1.05rem;
      text-decoration: none;
    }

    i.fad {
      margin-right: 4px;
    }
  }
}

.badge {
  position: absolute;
  top: -.2rem;
  right: -.1rem;
  transition: all .2s;
  opacity: .3;
}

.active .badge {
  top: -.5rem;
  right: -.5rem;
  opacity: 1;
}

.link-text {
  transition: all .2s;
}
</style>