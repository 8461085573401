import Vue from 'vue'

import { api } from './api'

import {extractContent} from './helpers'

export const authModule = {
  namespaced: true,
  state: {
    loggedIn: false,
    userId: "",
    projectId: "",
  },
  getters: {},
  mutations: {
    logInUser(state, userId, projectId) {
      console.debug('logInUser ', userId);

      state.loggedIn = true;
      state.userId = userId;
      state.projectId = projectId;
      localStorage.setItem("lastLogin", new Date().toISOString());
      localStorage.setItem("userId", state.userId);
    },
    logOutUser(state) {
      console.debug('logOutUser');
      state.loggedIn = false;
      state.userId = "";
      state.projectId = "";
      localStorage.removeItem("lastLogin");
      localStorage.removeItem("userId");
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("project");
      sessionStorage.removeItem("scrape");
    },
  },
  actions: {
    logIn(context, credentials) {
      return new Promise((resolve, reject) => {
        if (context.loggedIn) {
          resolve({status: 200, msg: "Already logged in."});
        } else {
          context.commit('setRequestState', true, {root: true});

          api.post(`/auth/`, credentials)
              .then(res => {
                console.debug("logIn res", res);

                let data = res.data;

                let user = data.user;
                let project = data.project;
                let projects = data.projects;
                let scrape = data.scrape;

                if (scrape) {
                  project.externalNodes.concat(extractContent(scrape.netNodes, context.rootState.platforms));
                }

                context.commit('logInUser', user._id, project._id);
                context.commit('user/mergeUserData', user, {root: true});
                context.commit('project/mergeProjectData', project, {root: true});
                context.commit('project/setProjectSelection', projects, {root: true});
                context.commit('project/mergeScrapeData', scrape, {root: true});

                context.commit('setRequestState', false, {root: true});
                resolve({status: res.status, msg: res.statusText, user, projects, project, scrape});
              })
              .catch(err => {
                console.log(err);
                context.commit('setRequestState', false, {root: true});
                reject({status: err.status, msg: err});
              });
        }
      });
    },
    logOut(context) {
      if (context.state.loggedIn) {
        context.commit('logOutUser');
        context.commit('user/removeUserData', {}, {root: true});
        context.commit('project/removeProjectData', {}, {root: true});
        context.commit('project/removeScrapeData', {}, {root: true});
        Vue.prototype.$socket.sendObj({action: 'logOut'})
      }
    },
    signUp(context, newUser) {
      console.debug('signUp');
      return new Promise((resolve, reject) => {
        if (context.rootState.dataRequestPending) {
          reject('another request is still pending');
        }

        context.commit('setRequestState', true, {root: true});

        console.debug('signUp getUserData', newUser);

        api.post(`/auth/signup/`, newUser)
            .then(res => {
              console.log('signUp res', res);

              let data = res.data;

              let user = data.user;
              let project = data.project;
              let projects = data.projects;
              let scrape = data.scrape;

              if (scrape) {
                project.externalNodes.concat(extractContent(scrape.netNodes, context.rootState.platforms));
              }

              context.commit('logInUser', user._id);
              context.commit('user/mergeUserData', user, {root: true});
              context.commit('project/mergeProjectData', project, {root: true});
              context.commit('project/setProjectSelection', projects, {root: true});
              context.commit('project/mergeScrapeData', scrape, {root: true});

              context.commit('setRequestState', false, {root: true});
              resolve({status: res.status, msg: res.statusText, user, projects, project});
            })
            .catch(err => {
              console.log('signUp err', err);
              context.commit('setRequestState', false, {root: true});
              reject({status: err.status, msg: err});
            })
      });
    },
    checkInvite(context, projectId) {
      console.debug('checkInvite projectId', projectId);
      return new Promise((resolve, reject) => {
        if (context.rootState.dataRequestPending) {
          reject('another request is still pending');
        }

        context.commit('setRequestState', true, {root: true});

        api.get(`/auth/invite/${projectId}`)
            .then(res => {
              console.debug("checkInvite res", res);
              let project = res.data.project;

              context.commit('project/setProjectSelection', [project], {root: true});

              context.commit('setRequestState', false, {root: true});
              resolve({status: res.status, msg: res.statusText, project});
            })
            .catch(err => {
              console.log(err);
              context.commit('setRequestState', false, {root: true});
              reject({status: err.status, msg: err});
            });
      });
    },
    signUpWithInvite(context, projectId) {
      console.debug('signUpWithInvite projectId', projectId);
      return new Promise((resolve, reject) => {
        if (context.rootState.dataRequestPending) {
          reject('another request is still pending');
        }

        context.commit('setRequestState', true, {root: true});

        let user = context.rootGetters.getUser;

        console.debug('updateUser getUserData', user);
        api.post(`/auth/invite/${projectId}`, user)
            .then(res => {
              console.debug("signUpWithInvite res", res);
              let data = res.data;

              let user = data.user;
              let projects = data.projects;

              context.commit('logInUser', user._id);
              context.commit('user/mergeUserData', user, {root: true});
              context.commit('project/setProjectSelection', projects, {root: true});

              context.commit('setRequestState', false, {root: true});
              resolve({status: res.status, msg: res.statusText, user, projects});
            })
            .catch(err => {
              console.log(err);
              context.commit('setRequestState', false, {root: true});
              reject({status: err.status, msg: err});
            })
      });
    },
    init(context) {
      return new Promise((resolve, reject) => {
        if (context.rootState.dataRequestPending) {
          reject('another request is still pending');
        }

        context.commit('setRequestState', true, {root: true});
        console.debug("Init.");

        if (context.state.loggedIn === true && context.state.userId) {
          console.debug("Already logged in. Init complete.");
          context.commit('setRequestState', false, {root: true});
          resolve();
        }

        let lastLogin = localStorage.getItem("lastLogin");
        let sameSession = lastLogin ? Date.parse(lastLogin) > Date.now() - 1000 * 60 * 60 * 4 : false;

        if (sameSession) {
          context.commit('logInUser', localStorage.getItem("userId"));

          let sessionUser = sessionStorage.getItem("user");
          let sessionProject = sessionStorage.getItem("project");
          let sessionNodes = sessionStorage.getItem("netNodes");
          let sessionLinks = sessionStorage.getItem("netLinks");
          let sessionTechNodes = sessionStorage.getItem("techNodes");
          let sessionContentNodes = sessionStorage.getItem("contentNodes");
          let sessionScrape;

          if (sessionUser && sessionUser !== "undefined") {
            context.commit("user/mergeUserData", JSON.parse(sessionUser), {root: true});
          }
          if (sessionProject && sessionProject !== "undefined") {
            context.commit("project/mergeProjectData", JSON.parse(sessionProject), {root: true});
          }
          if (sessionNodes && sessionLinks && sessionTechNodes && sessionContentNodes) {
            sessionScrape = {
              netNodes: sessionNodes,
              netLinks: sessionLinks,
              techNodes: sessionTechNodes,
              contentNodes: sessionContentNodes
            }
            context.commit("project/mergeScrapeData", sessionScrape, {root: true});
          }

          if (sessionUser && sessionProject && sessionScrape) {
            context.commit('setRequestState', false, {root: true});
            console.debug("Data pre-retrieved from SessionStorage. Updating.");
          }

          context.commit('setRequestState', true, {root: true});
          console.debug("Retrieving User Data.", context.state.userId);
          api.get(`/api/users/${context.state.userId}`)
              .then(res => {
                console.debug("init res", res);

                let data = res.data;

                let user = data.user;
                let project = data.project;
                let projects = data.projects;
                let scrape = data.scrape;

                if (scrape) {
                  project.externalNodes.concat(extractContent(scrape.netNodes, context.rootState.platforms));
                }

                context.commit('logInUser', user._id);
                context.commit('user/mergeUserData', user, {root: true});
                context.commit('project/mergeProjectData', project, {root: true});
                context.commit('project/setProjectSelection', projects, {root: true});
                context.commit('project/mergeScrapeData', scrape, {root: true});

                context.commit('setRequestState', false, {root: true});
                resolve({status: res.status, msg: res.statusText, user, projects});
              })
              .catch(err => {
                console.log(err);
                console.debug("Client not logged in. Redirecting to login.");
                context.commit('setRequestState', false, {root: true});
                reject({status: err.status, msg: err});
              });

        } else {
          context.dispatch('logOut')
              .then(() => {
                context.commit('setRequestState', false, {root: true});
                reject({msg: "Not logged in."})
              });
        }
      });
    },
  }
}
