let userTemplate = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  logo: "",
  telephone: "",
  address: {
    name: "",
    street: "",
    additionalField: "",
    city: "",
    zipCode: "",
    country: "",
  },
  _projectIds: [],
  settings: {
    advanced: false,
    chatColor: "",
    chatMute: false,
    hideOnline: false,
  },
  activeProjectId: "",
  accountType: [],
  createdAt: new Date()
}

module.exports = userTemplate;