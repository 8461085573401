export function extractContent(netNodes, platforms) {
  let externalNodes = [];
  netNodes.forEach(node => {
    Object.values(platforms).forEach(platform => {
      if (node.url.indexOf(platform.title.toLowerCase()) >= 0) {
        Object.assign(node, {
          platform: platform.title,
          ads:[],
          category:"",
          description:"",
          embedCode:"",
          intents:[],
          links:[],
          nodeUrls:[],
          state:"Live",
          tags:[],
          timeline: {updates:[]},
          topics:[],
        });
        /*console.log(node);*/
        externalNodes.push(node);
      }
    });
  });
  return externalNodes;
}

export function mergeManualNodes(manualNodes, netNodes) {
  let nodes = [];
  netNodes.map(nNode => {
    let mNodeIndex = manualNodes.findIndex(mNode => mNode.url === nNode.url);
    if (mNodeIndex >= 0) {
      Object.assign(nNode, manualNodes[mNodeIndex]);
      manualNodes.splice(mNodeIndex, 1);
    }
    nodes.push(nNode);
  });
  nodes.concat(manualNodes);
  return nodes;
}