let scrapeTemplate = {
  _projectId: "",
  netNodes: [],
  netLinks: [],
  techNodes: [],
  contentNodes: [],
  scrapeSummary: {
    exitCode: 0,
    approval: {},
    log: "",
    createdAt: new Date(),
    finishedAt: null,
  }
}

module.exports = scrapeTemplate;