import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueNativeSock from 'vue-native-websocket'

import { mapState, mapActions } from 'vuex'

Vue.config.productionTip = false;

const socketProtocol = (process.env.VUE_APP_API_PROTOCOL === 'https:' ? 'wss:' : 'ws:');
const socketUrl = socketProtocol + '//' + process.env.VUE_APP_API_HOST + '/websocket';

Vue.use(VueNativeSock, socketUrl, {
  store,
  format: 'json',
  reconnection: true,
  reconnectionAttempts: 10,
  reconnectionDelay: 1000,
  passToStoreHandler: function (eventName, event) {
    if (!eventName.startsWith('SOCKET_')) { return }
    let method = 'commit'
    let target = 'socket/' + eventName.toUpperCase()
    let msg = event
    if (this.format === 'json' && event.data) {
      msg = JSON.parse(event.data)
      if (msg.mutation) {
        target = 'socket/' + msg.mutation
      } else if (msg.action) {
        method = 'dispatch'
        target = 'socket/' + msg.action
      }
    }
    this.store[method](target, msg, this.store);
  }
})

Vue.mixin({
  data() {
    return {
      routes: [
        {
          title: "Navigation",
          filtered: false, hidden: true,
          description: ``,
          links: [
            {
              title: 'Toolbox Übersicht', icon: 'fa-box-full', prop: '', route: '/', advanced: false,
              description: 'Deine Web Strategy Toolbox. Vom Scraper bis zur Roadmap für die Umsetzung deiner Ideen alles dabei.',
            },
            {
              title: 'Toolbox Übersicht', icon: 'fa-box-full', prop: '', route: '/overview', advanced: false,
              description: 'Deine Web Strategy Toolbox. Vom Scraper bis zur Roadmap für die Umsetzung deiner Ideen alles dabei.',
            },
            {
              title: 'Login', icon: 'fa-key', prop: '', route: '/login', advanced: false,
              description: 'Deine Web Strategy Toolbox. Jetzt einloggen.',
            },
            {
              title: 'Sign Up', icon: 'fa-sign-in-alt', prop: '', route: '/signup', advanced: false,
              description: 'Deine Web Strategy Toolbox. Vom Scraper bis zur Roadmap für die Umsetzung deiner Ideen alles dabei.',
            },
            {
              title: 'Logout', icon: 'fa-door-open', prop: '', route: '/logout', advanced: false,
              description: 'Deine Web Strategy Toolbox. Vom Scraper bis zur Roadmap für die Umsetzung deiner Ideen alles dabei.',
            },
            {
              title: 'Deine Theorien', icon: 'fa-drafting-compass', prop: 'theories', route: '/theories', advanced: false,
              description: 'Der Dreh- und Angelpunkt deiner Optimierungsreise. Teste verschiedene erprobte Methoden, um deine Seite noch besser zu machen.',
            },
            {
              title: 'Projekteinstellungen', icon: 'fa-cogs', prop: 'projectSelection', route: '/projects', advanced: false,
              description: 'Erstelle, bearbeite und wechsle deine Projekte.',
            },
            {
              title: 'Dein Projekt', icon: 'fa-rocket', prop: 'projectSelection', route: '/project/edit', advanced: false,
              description: 'Verwalte die Einstellungen und Daten deines Teams.',
            },
            {
              title: 'Dein Profil', icon: 'fa-user-astronaut', prop: '', route: '/profile', advanced: false,
              description: 'Bearbeite und aktualisiere deine Nutzerdaten.',
            },
            {
              title: 'Dashboard', icon: 'fa-space-shuttle', prop: '', route: '/dashboard', advanced: false,
              description: 'Die Übersicht deiner Optimierungsreise.',
            },
          ]
        },
        {
          title: "Website",
          filtered: false, hidden: false,
          description: `Hier wird die Grundlage für die Toolbox gelegt. Scrape deine Seiten und erweitere sie, um sie später für deine Ideengenerierung zu nutzen.`,
          links: [
            {
              title: 'Scraper', icon: 'fa-cloud-download', prop: 'scraper', route: '/scraper', advanced: true,
              hidden: true,
              description: 'Benutze den Scraper um deine Website inklusive Screenshots und Verlinkungen automatisch in das Tool zu laden.',
            },
            {
              title: 'Link Tree', icon: 'fa-chart-network', prop: 'links', route: '/links', advanced: false,
              description: 'Hier findest du die gescrapeten Infos zu deiner Website und wie deine Linkstruktur aufgebaut ist.',
            },
            {
              /*title: 'Content Pieces', icon: 'fa-book-spells', prop: 'contents', route: '/contents', advanced: false,*/
              title: 'Externer Content', icon: 'fa-code-merge', prop: 'contents', route: '/contents', advanced: false,
              description: 'Füge externe Links zu deinem Link Tree hinzu, um deine Einblicke zu verbessern und Möglichkeiten zu erweitern.',
            },
          ]
        },
        {
          title: "Erkenntnisse",
          filtered: false, hidden: false,
          description: `Verfeinere dein Verständnis deiner Website mit nutzerzentrierten Methoden in deiner zentralen Wissenssammlung.`,
          links: [
            {
              title: 'Werteversprechen', icon: 'fa-sparkles', prop: 'canvas', route: '/canvas', advanced: false,
              description: 'Sammle, was dein Unternehmen und deine Produkte ausmacht und was deine Kunden daran wertschätzen und befürchten.',
            },
            {
              title: 'Nutzer-Jobs', icon: 'fa-construction', prop: 'jobs', route: '/jobs', advanced: true,
              description: 'Hier kannst du die Jobs und Ziele deiner Nutzer mit deinen Produkten genauer definieren.',
            },
            {
              title: 'Personas', icon: 'fa-user-edit', prop: 'personas', route: '/personas', advanced: false,
              description: 'Personas ermöglichen dir, deine Ideen noch treffgenauer auf deine Kunden abzustimmen.',
            },
            {
              title: 'Customer Journeys', icon: 'fa-route', prop: 'journeys', route: '/journeys', advanced: false,
              description: 'Bilde mit Customer Journeys die Pfade ab, den Nutzer auf deiner Website gehen können, um an dein Ziel zu gelangen.',
            },
          ]
        },
        {
          title: "Ideenfindung",
          filtered: true, hidden: false,
          description: `Das Herzstück der Toolbox, um aus dem gesammelten Wissen, neue, wertvolle Ideen zu generieren.`,
          links: [
            {
              title: 'Website Audits', icon: 'fa-stethoscope', prop: 'audits', route: '/audits', advanced: false,
              description: 'Sammle anhand der Screenshots deiner Seite Probleme und Bedenken, auf die deine Nutzer treffen könnten.',
            },
            {
              title: 'Potenziale', icon: 'fa-radar', prop: 'findings', route: '/findings', advanced: false,
              description: 'Hier findest du alle gefundenen Probleme in deiner Seiten, um daraus Hypothesen zur Verbesserung zu bilden.',
            },
            {
              title: 'Hypothesen', icon: 'fa-question-circle', prop: 'hypotheses', route: '/hypotheses', advanced: true,
              description: 'Mit dem Hypothesen-Tool erstellst du Annahmen, die dir dabei helfen, gesammelte Probleme und Potenziale zu validieren.',
            },
            {
              title: 'Brainstorms', icon: 'fa-mind-share', prop: 'brainstorm', route: '/brainstorm', advanced: false,
              description: 'Um deine Hypothesen in Ideen zur Überprüfung umzuwandeln, kannst du hier deine Infos sammeln, clustern und bewerten.',
            },
          ]
        },
        {
          title: "Validierung",
          filtered: true, hidden: false,
          description: `Die Validierungstools helfen dir dabei, deine Ideen zu überprüfen und herauszufinden, was funktioniert und was nicht.`,
          links: [
            {
              title: 'Ideen Backlog', icon: 'fa-lightbulb', prop: 'ideas', route: '/ideas', advanced: false,
              description: 'Hier werden deine Ideen gesammelt und priorisiert, um deine Optimierung effizient und übersichtlich zu gestalten.',
            },
            {
              title: 'Experimente', icon: 'fa-microscope', prop: 'experiments', route: '/experiments', advanced: true,
              description: 'Formuliere hier den Status Quo und deine Änderung, um eine Grundlage für die Bewertung deiner Ideen zu haben.',
            },
            {
              title: 'Learnings', icon: 'fa-clipboard-check', prop: 'learnings', route: '/learnings', advanced: true,
              description: 'Ergebnisse von Experimenten können hier kommentiert und konsolidiert werden, um sie für neue Erkenntnisse einfließen zu lassen.',
            },
            {
              title: 'Erfolgsberechnung', icon: 'fa-calculator', prop: 'calculations', route: '/calculations', advanced: false,
              description: 'Ein Rückblick auf deine Experimente und welche Auswirkungen sie auf die Performance deiner Website haben.',
            },
          ]
        },
        {
          title: "Organisation",
          filtered: false, hidden: false,
          description: `Um dich und dein Team strukturiert zu halten, kannst du hier Slots für deine Experimente planen und Notizen für alle anfertigen.`,
          links: [
            {
              title: 'Roadmap', icon: 'fa-calendar', prop: 'roadmaps', route: '/roadmaps', advanced: false,
              description: 'Auf der Roadmap erstellst du einfach Termine und Zeiträume für deine Experimente, um Überschneidungen zu vermeiden und zu planen.',
            },
            {
              title: 'Notizen', icon: 'fa-sticky-note', prop: 'notes', route: '/notes', advanced: false,
              description: 'Notizen können unabhängig vom Rest der Toolbox als Platz für Wissen, Pläne, Strategien und anderes benutzt werden.',
            },
          ]
        },
        /*
        {title: 'Traffic Channels', icon: 'fa-sitemap', prop: 'channels', route: '/channels'},
        {title: 'KPI Tool', icon: 'fa-funnel-dollar', prop: 'kpis', route: '/kpis'},
        {title: 'Campaign Planner', icon: 'fa-hashtag', prop: 'campaigns', route: '/campaigns'},
        {title: 'Competition Benchmark', icon: 'fa-telescope', prop: 'benchmarks', route: '/benchmarks'},
        {title: 'To Do',                 icon: 'fa-list',              prop: 'todos',            route: '/todos'},
        {title: 'Behaviour Patterns',    icon: 'fa-head-side-brain',   prop: 'patterns',         route: '/patterns'},
        {title: 'Best Practices',        icon: 'fa-box-full',          prop: 'practices',        route: '/practices'},
        {title: 'Personalisation',       icon: 'fa-user-tag',          prop: 'personalisations', route: '/personalisations'},
        {title: 'Style Guide',           icon: 'fa-brush',             prop: 'styles',           route: '/styles'},
        */
      ],
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      project: state => state.project,
      socket: state => state.socket,
      auth: state => state.auth,
      blockUI: state => state.blockUI,
      dataRequestPending: state => state.dataRequestPending,
    }),
    ...mapActions({

    }),
    activeRoute() {
      let path = this.$route.path;
      if (Object.values(this.$route.params).length) {
        path = this.$route.path.replace(Object.values(this.$route.params)[0], "");
      }
      return this.getRoute(path);
    },
    nextApproval() {
      if (!this.project.scraper.full.length || this.isAdmin) return 0;
      let lastApproval = this.project.scraper.full[this.project.scraper.full.length - 1];
      let date = new Date(lastApproval.date);
      let today = new Date();
      let millisPerDay = 1000 * 60 * 60 * 24;
      let millisBetween = date.getTime() - today.getTime();
      let days = millisBetween / millisPerDay;
      let maxDays = this.project.scraper.fullScrapeFrequency || 0;
      return maxDays - Math.abs(Math.floor(days));
    },
    clientHasDragging() {
      return 'draggable' in document.createElement('span')
    },
    notifications() {
      return this.project.notifications;
    },
    adminOverride() {
      return this.$store.state.adminOverride;
    },
    isAdmin() {
      return this.user.accountType ? (
          this.user.accountType.indexOf("admin") >= 0 && !this.adminOverride) : false;
    },
    isLoggedIn() {
      return this.auth.loggedIn;
    },
    isAdvanced() {
      return this.user.settings.advanced;
    },
    isOwnProject() {
      return this.projectOwned(this.project);
    },
    activeTheoryId() {
      let member = this.project.members.find(m => m.email === this.user.email);
      return this.isLoggedIn && member && member.settings ? member.settings.activeTheoryId : null;
    },
    activeTheory() {
      return this.project.theories && this.activeTheoryId ? this.project.theories.find(theory => theory.id === this.activeTheoryId) : { title: "Theorie-Filter" };
    },
    scraperScheduled() {
      return this.project.scraper.status ? this.project.scraper.status.includes("scheduled") : false;
    },
    scraperRunning() {
      return this.project.scraper.status ? this.project.scraper.status.includes("running") || this.project.scraper.status.includes("scheduled") : false;
    },
    scraperError() {
      return this.project.scraper.status ? this.project.scraper.status.includes("error") : false;
    },
  },
  methods: {
    pSBC(p,c0,c1,l) {
      // https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)#stackoverflow-archive-begin
      let r,g,b,P,f,t,h,i=parseInt,m=Math.round,a=typeof(c1)=="string";
      if(typeof(p)!="number"||p<-1||p>1||typeof(c0)!="string"||(c0[0]!='r'&&c0[0]!='#')||(c1&&!a))return null;
      if(!this.pSBCr)this.pSBCr=(d)=>{
        let n=d.length,x={};
        if(n>9){
          [r,g,b,a]=d=d.split(","),n=d.length;
          if(n<3||n>4)return null;
          x.r=i(r[3]=="a"?r.slice(5):r.slice(4)),x.g=i(g),x.b=i(b),x.a=a?parseFloat(a):-1
        }else{
          if(n==8||n==6||n<4)return null;
          if(n<6)d="#"+d[1]+d[1]+d[2]+d[2]+d[3]+d[3]+(n>4?d[4]+d[4]:"");
          d=i(d.slice(1),16);
          if(n==9||n==5)x.r=d>>24&255,x.g=d>>16&255,x.b=d>>8&255,x.a=m((d&255)/0.255)/1000;
          else x.r=d>>16,x.g=d>>8&255,x.b=d&255,x.a=-1
        }return x};
      h=c0.length>9,h=a?c1.length>9?true:c1=="c"?!h:false:h,f=this.pSBCr(c0),P=p<0,t=c1&&c1!="c"?this.pSBCr(c1):P?{r:0,g:0,b:0,a:-1}:{r:255,g:255,b:255,a:-1},p=P?p*-1:p,P=1-p;
      if(!f||!t)return null;
      if(l)r=m(P*f.r+p*t.r),g=m(P*f.g+p*t.g),b=m(P*f.b+p*t.b);
      else r=m((P*f.r**2+p*t.r**2)**0.5),g=m((P*f.g**2+p*t.g**2)**0.5),b=m((P*f.b**2+p*t.b**2)**0.5);
      a=f.a,t=t.a,f=a>=0||t>=0,a=f?a<0?t:t<0?a:a*P+t*p:0;
      if(h)return"rgb"+(f?"a(":"(")+r+","+g+","+b+(f?","+m(a*1000)/1000:"")+")";
      else return"#"+(4294967296+r*16777216+g*65536+b*256+(f?m(a*255):0)).toString(16).slice(1,f?undefined:-2)
    },
    generateId() {
      const upperLetters = "ABCDEFGHIJKLMNNOPQRSTUVWXYZ";
      const lowerLetters = upperLetters.toLowerCase();
      const letters = upperLetters.split("").concat(lowerLetters.split(""));
      const digits = "0123456789";
      const symbols = letters.concat(digits.split(""));

      let id = letters[Math.floor(Math.random() * letters.length)];
      for (let i = 0; i < 10; i++) {
        id += symbols[Math.floor(Math.random() * symbols.length)];
      }
      return id;
    },
    findNode(nodeUrl) {
      let node = this.project.netNodes.find(n => n.url === nodeUrl) || this.project.externalNodes.find(n => n.url === nodeUrl);
      if (node) {
        node = Object.assign(node || {}, this.project.contentNodes.find(n => n.url === nodeUrl));
        node = Object.assign(node || {}, this.project.techNodes.find(n => n.url === nodeUrl));

        if (!node.title && node.url) {
          node.title = node.url.replace(this.rootUrl, "");
        }
      }
      if (!node) {
        node = {
          id: this.generateId(),
          url: nodeUrl === "Platzhalter" ? "Platzhalter" : nodeUrl,
          title: nodeUrl === "Platzhalter" ? "Platzhalter" : nodeUrl,
          status: 200,
          image: {
            thumb: "",
            desktop: "",
            tablet: "",
            mobile: ""
          }
        };
      }
      node.metrics = this.project.nodeMetrics.find(n => n.url === nodeUrl) || {};
      node.attributes = this.project.nodeAttributes.find(n => n.url === nodeUrl) || {};
      let type = this.project.nodePagetypes.find(n => n.url === nodeUrl);
      node.pagetype = type ? this.getPagetype(type.id) : "";
      return node;
    },
    getPagetype(typeId) {
      let type = this.project.settings.nodePagetypes.find(n => n.id === typeId);
      return type ? type.title : "";
    },
    projectOwned(project) {
      return project.members.find(m => m.email === this.user.email);
    },
    getRoute(path) {
      let routes = this.routes.reduce((acc, g) => acc.concat(g.links), []);
      let route = routes.find(r => r.route.indexOf(path) >= 0);

      if (!route) {
        path = path.split("/").slice(0, 2).join("/");
        route = routes.find(r => r.route.indexOf(path) >= 0);
      }
      return route;
    },
    cur(x) {
      return x.toLocaleString() + ' €';
    },
    img(url) {
      if (url && url.indexOf("http") === 0) {
        return url;
      }
      try {
        return `@/assets/${url}`;
      } catch (Error) {
        console.warn("Image not found.");
        console.warn("@/assets/" + url);
        throw Error;
      }
    },
    getImg(node, size) {
      if (!size) size = 'thumb';
      if (!node.image || !node.image[size]) return require("@/assets/no-image.png");
      let scrapeId = this.project.scraper.full[this.project.scraper.full.length - 1].id;
      try {
        return `${node.image[size]}`;
      } catch (Error) {
        console.warn("Image for '" + (node.title ? node.title : "") + "', ID " + (node.id ? node.id : "") + " not found.");
        console.warn(`/python/data/${scrapeId}-temp/img/${node.image[size]}`);
      }
    },
    checkUrl(url) {
      return url.match(
          /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
      );
    },
    nextSingleApproval(lastApproval) {
      let date = new Date(lastApproval.date);
      let today = new Date();
      let millisPerDay = 1000 * 60 * 60 * 24;
      let millisBetween = date.getTime() - today.getTime();
      let days = millisBetween / millisPerDay;
      return this.project.scraper.singleScrapeFrequency - Math.abs(Math.floor(days));
    },
    timestampToDate(timestamp) {
      return new Date(timestamp).toISOString().slice(0, 10);
    },
    toggleAdminOverride() {
      this.$store.commit('toggleAdminOverride');
    }
  },
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
