<template>
  <nav :style="themeColor" class="navbar navbar-expand-md navbar-dark bg-dark" :class="isLoggedIn && $route.path !== '/setup' ? '' : 'mb-5'">
    <div @click="openProjectDropdown" class="navbar-brand nav-item dropdown">
      <h1 v-if="!isLoggedIn" class="h6 mb-0">
        <i class="fad fa-box-check fa-2x align-middle mr-2"></i>
        Web Strategy Toolbox
      </h1>
      <h1 v-else class="row h6 mb-0 align-items-center">
        <span class="col-auto pr-2">
          <span v-if="project.profile.logo" class="navbar-logo-wrapper">
            <img :src="project.profile.logo" alt="Projekt-Logo" class="navbar-logo img-fluid">
          </span>
          <i v-else class="fad fa-fw fa-box-check fa-2x"></i>
        </span>
        <span class="col-auto pl-0">
          <strong>{{ projectName || (dataRequestPending ? 'Lädt...' : 'Neues Projekt') }}</strong>
          <i class="fas fa-caret-down fa-pull-right"></i><br>
          Web Strategy Toolbox
        </span>
      </h1>

      <div v-show="projectDropdownOpen" class="dropdown-menu">
        <router-link class="dropdown-item" to="/dashboard" active-class="active">
          <i class="fad" :class="getRoute('/dashboard').icon"></i> {{ getRoute('/dashboard').title }}
        </router-link>
        <router-link class="dropdown-item" to="/projects" active-class="active">
          <i class="fad" :class="getRoute('/projects').icon"></i> {{ getRoute('/projects').title }}
        </router-link>
        <div v-if="projectSelection.length > 1" class="dropdown-divider"></div>
        <span v-if="projectSelection.length > 1" class="dropdown-item text-muted">Projekt wechseln:</span>
        <a v-for="(selection, index) in projectSelection.filter(s => s._projectId !== user.activeProjectId)"
           :key="index"
           class="dropdown-item"
           :class="projectOwned(selection) ? '' : 'text-muted'"
           @click="switchProject(selection._projectId)"
        >
          <span class="d-flex align-items-center">
            <span class="project-indicator mr-2">
              <span v-if="selection.profile.logo" class="avatar-wrapper">
                <img :src="selection.profile.logo" alt="" class="img-fluid avatar">
              </span>
              <span v-else class="color-orb" :style="'background:' + selection.settings.themeColor"></span>
            </span>
            <span class="project-name">
              <i v-if="selection.deleted" class="fad fa-trash"></i>
              <i v-else-if="!projectOwned(selection)" class="fad fa-bug"></i>
              {{ selection.profile.projectName }}
            </span>
          </span>
        </a>
      </div>
    </div>
    <button class="navbar-toggler" type="button" @click="openMobileMenu">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div :class="mobileMenuOpen ? 'show' : ''" class="collapse navbar-collapse">
      <ul class="navbar-nav mr-auto text-center text-lg-left align-items-center">
        <li v-if="!isLoggedIn" class="nav-item">
          <router-link class="nav-link" to="/overview" active-class="active">
            <i class="fad" :class="getRoute('/overview').icon"></i> {{ getRoute('/overview').title }}
          </router-link>
        </li>
        <li v-if="isLoggedIn && project.theories && isOwnProject" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="javascript:void(0)" @click="openTheoryDropdown">
            <i class="fad" :class="activeTheory.icon || getRoute('/theories').icon"></i>
            <br class="d-lg-none">
            {{ activeTheory.title }}
          </a>
          <div v-show="theoryDropdownOpen" class="dropdown-menu">
            <span v-if="project.theories.filter(t => t.id !== activeTheoryId).length" class="dropdown-item text-muted">
              Nach Theorie filtern:
            </span>
            <a v-for="(theory, index) in project.theories.filter(t => t.id !== activeTheoryId)"
               :key="index"
               class="dropdown-item"
               @click="switchTheory(theory.id)"
            >
              <i class="fad" :class="theory.icon"></i> {{ theory.title }}
            </a>
            <a v-if="!!activeTheoryId" class="dropdown-item" @click="switchTheory('')">
              <i class="fal fa-ban"></i> Theorie-Filter ausschalten
            </a>
            <div v-if="project.theories.filter(t => t.id !== activeTheoryId).length" class="dropdown-divider"></div>
            <router-link class="dropdown-item" to="/theories" active-class="active">
              <i class="fad" :class="getRoute('/theories').icon"></i> Deine Theorie-Übersicht
            </router-link>
          </div>
        </li>
        <li v-else-if="isLoggedIn && isAdmin && (!isOwnProject || project.deleted)">
          <span class="navbar-text">
            <i class="fad fa-bug"></i> Admin-Modus
          </span>
        </li>
        <li v-if="isLoggedIn && scraperRunning" class="nav-item my-0">
          <router-link to="/scraper" class="nav-link" active-class="active">
            <div class="row align-items-center">
              <div class="col-auto pr-0 h5 mb-0">
                <i class="fad fa-cloud-download"></i>
              </div>
              <div class="col pl-2 small">
                Scraper läuft: <br>
                {{ scraperUpdate }}
              </div>
            </div>
          </router-link>
        </li>
      </ul>
      <ul class="navbar-nav text-center text-lg-left">
        <!--<li class="nav-item">
          <router-link class="nav-link" to="/overview" active-class="active">
            <i class="fad" :class="getRoute('/overview').icon"></i> {{ getRoute('/overview').title }}
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/guide" active-class="active">
            <i class="fad" :class="getRoute('/guide').icon"></i> {{ getRoute('/guide').title }}
          </router-link>
        </li>-->
        <li v-if="!isLoggedIn" class="nav-item">
          <router-link class="nav-link" to="/login" active-class="active">
            <i class="fad" :class="getRoute('/login').icon"></i> {{ getRoute('/login').title }}
          </router-link>
        </li>
        <li v-if="!isLoggedIn" class="nav-item">
          <router-link class="nav-link" to="/signup" active-class="active">
            <i class="fad" :class="getRoute('/signup').icon"></i> {{ getRoute('/signup').title }}
          </router-link>
        </li>
        <li v-if="isLoggedIn" class="nav-item">
          <router-link class="nav-link" to="/profile" active-class="active">
            <i class="fad" :class="getRoute('/profile').icon"></i> {{ getRoute('/profile').title }}
          </router-link>
        </li>
        <li v-if="isLoggedIn" class="nav-item">
          <router-link class="nav-link" to="/logout" active-class="active">
            <i class="fad" :class="getRoute('/logout').icon"></i> {{ getRoute('/logout').title }}
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      hideLinkTexts: false,
      projectDropdownOpen: false,
      theoryDropdownOpen: false,
      mobileMenuOpen: false,
    }
  },
  computed: {
    projectName() {
      return this.project.profile.projectName;
    },
    projectSelection() {
      return this.project.projectSelection ? this.project.projectSelection : [];
    },
    projectId() {
      return this.project.projectId;
    },
    themeColor() {
      let themeColor = this.project.settings.themeColor;
      return themeColor ? 'background:' + themeColor + '!important' : '';
    },
    scraperUpdate() {
      let update = this.socket.scraperUpdate;
      return update.msg || "Warten auf Lebenszeichen";
    },
  },
  methods: {
    openMobileMenu($event) {
      $event.preventDefault();
      if (this.mobileMenuOpen) {
        this.mobileMenuOpen = false;
      } else {
        this.mobileMenuOpen = true;
        let f = event => {
          if (event.target !== $event.target && !event.target.classList.contains('dropdown-toggle')) {
            this.mobileMenuOpen = false;
            document.removeEventListener('click', f);
          }
        }
        document.addEventListener("click", f);
      }
    },
    openProjectDropdown($event) {
      $event.preventDefault();
      if (!this.isLoggedIn) {
        this.$router.push("/");
      } else {
        if (this.projectDropdownOpen) {
          this.projectDropdownOpen = false;
        } else {
          this.projectDropdownOpen = true;
          let f = event => {
            if (event.target !== $event.target) {
              this.projectDropdownOpen = false;
              document.removeEventListener('click', f);
            }
          }
          document.addEventListener("click", f);
        }
      }
    },
    openTheoryDropdown($event) {
      $event.preventDefault();
      if (this.theoryDropdownOpen) {
        this.theoryDropdownOpen = false;
      } else {
        this.theoryDropdownOpen = true;
        let f = event => {
          if (event.target !== $event.target) {
            this.theoryDropdownOpen = false;
            document.removeEventListener('click', f);
          }
        }
        document.addEventListener("click", f);
      }
    },
    switchProject(projectId) {
      this.hideUI = true;
      this.$store.dispatch('project/switchProject', projectId)
          .then(res => {
            console.debug(res);
          })
          .finally(() => {
            this.hideUI = false;
          });
    },
    switchTheory(theoryId) {
      this.hideUI = true;
      let members = JSON.parse(JSON.stringify(this.project.members));
      members.find(member => member.email === this.user.email).settings.activeTheoryId = theoryId;

      if (members.length === 0) {
        console.warn("Error switching Theory");
      } else {
        this.$store.dispatch('project/updateProjectByProp', {prop: 'members', data: members })
            .then(() => {
              this.hideUI = false;
              this.$forceUpdate();
            })
            .catch(err => {
              console.error(err);
              this.hideUI = false;
              this.$forceUpdate();
            });
      }
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  transition: background .5s, color .5s, height 1s;
  height: auto;
}
.navbar-brand.nav-item {
  background: rgba(255,255,255,.2);
  color: var(--white);
  padding: .3rem .75rem .3rem .3rem;
  cursor: pointer;
  border-radius: 3px;
}
.navbar-logo-wrapper {
  display: block;
  height: 2.4rem;
  width: 2.4rem;
  margin-left: .1rem;
  border-radius: 100%;
  overflow: hidden;
}
.navbar-dark .nav-link {
  color: rgba(255,255,255,.7);
  &:hover {
    color: rgba(255,255,255,.9);
  }
}
.project-indicator {
  display: inline-block;
  width: 24px;

  .avatar-wrapper {
    display: block;
    width: 100%;
    padding-top: 100%;
    margin: 0;
  }
  .color-orb {
    display: block;
  }
}
.project-name {
  display: inline-block;
  vertical-align: top;
  text-overflow: ellipsis;
  height: 1.5rem;
  width: calc(100% - 2rem);
}

.dropdown-menu {
  display: block;
  min-width: 100%;
}
.dropdown-toggle, .dropdown-item {
  cursor: pointer;

  &.text-muted {
    cursor: default;
    &:hover {
      background: revert;
    }
  }
}
</style>