import Vue from 'vue'

import { api } from './api'

const userTemplate = require('../../backend/templates/user.template');

export const userModule = {
  namespaced: true,
  state: {
    ...userTemplate
  },
  getters: {
    getUser(state) {
      console.debug("user/getUser", state);
      state = Object.assign({}, state);
      state.userId = state._id;
      return state;
    }
  },
  mutations: {
    mergeUserData(state, user) {
      console.debug('mergeUserData', user);

      if (user && user._id) {
        state.userId = user._id;
      } else if (!user) {
        return;
      }

      Object.assign(state, user);
      sessionStorage.setItem("user", JSON.stringify(user));
    },
    removeUserData(state) {
      console.debug('removeUserData');
      Object.assign(state, userTemplate);
      sessionStorage.removeItem("user");
    },
  },
  actions: {
    userUpdated(context, data) {
      console.debug('userUpdated', data);
      let user = data.user;
      context.commit('mergeUserData', user);
      Vue.prototype.$forceUpdate();
    },
    updateUser(context, newUser) {
      console.debug('updateUser');
      return new Promise((resolve, reject) => {
        if (context.state.dataRequestPending) {
          reject('another request is still pending');
        }

        context.commit('setRequestState', true, {root: true});

        let user = newUser || context.getters.getUser;

        console.debug('updateUser getUserData', user);
        api.put(`/api/users/`, user)
            .then(res => {
              console.debug('updateUser res', res);
              let data = res.data;

              let user = data.user;

              context.commit('mergeUserData', user);

              context.commit('setRequestState', false, {root: true});
              resolve({status: res.status, msg: res.statusText, user});
            })
            .catch(err => {
              console.log(err);
              context.commit('setRequestState', false, {root: true});
              reject({status: err.status, msg: err});
            })
      });
    },
  }
}
