export let patternFilters = {
    limbic:["Stimulanz","Balance","Dominanz","Kontrolle/Disziplin","Hidden"],
    level:["Relevanz","Vertrauen","Orientierung","Stimulanz","Komfort","Sicherheit","Bewertung","Hidden"]
}

export let practiceFilters = {
    patterns:["Affect Heuristic","Anchoring","Authenticity","Authority","Autonomy","Availability Heuristics","Banner Blindness","Bonding","Breaking Rules","Caring","Cashless Effect","Challenge","Cheering","Completion","Compromise Effect","Consistency/Commitment","Context","Contrast Effect","Curiosity","Decoy Effect","Default","Diderot Effect","Emotional Resonance","Endowment Effect","Faces and Views","Feedback","Flow","Framing","Free","Halo Effect","Hope","Hyperbolic discounting","Idleness Aversion","IKEA-Effect","Illusion of Control","Illusion of Truth","Implicit Signals","Inner Dialogue","Joy and Fun","Known","Kognitive Dissonanz","Kognitive Leichtigkeit","Loss Aversion","Mental Models","Mere Exposure Effect","Mirroring","Neomanie","Nonsense Math Effect","Nostalgia Effect","Nudging","Order and Harmony","Paradox of Choice","Patterns and Principles","Peak End Rule","Preview","Price Perception","Primacy-Recency Effect","Ranking","Reactance & Censoring","Reason Why","Reciprocity","Reward","Rhyme-as-Reason Effect","Rules","Scarcity","Self Expression","Self fulfilling prophecy","Sensation","Social Proof","Status Quo Bias","Story-Telling","Success","Swimmer's Body Illusion","Sympathy","Tension & Closure","Test Pattern","Testimonials","Trust","Try Out","Unlocking","Value","Well Travelled Road Effect","Zero-Risk Bias"],
    industry:["General","Automotive / Automobil","Bau- und Gartenmärkte","Bildungswesen","Büro- und Betriebsausstattung","Computer & Software","Informationsdienste","Digitaldruck","Drogerie & Kosmetik","E-Commerce & Versandhandel","Energie & Umwelt","Fashion Retailer / Fashion & Lifestyle","Finanzen, Versicherungen & Immobilien","Fotodienstleistungen","Hotelportal / Hotelreservierungsportal","Lebensmittel","Medien & Marketing","Optiker & Brillen","Pharma & Gesundheit","Schuhe","Spielwaren","Sport & Freizeit","Multimedia & Technik","Telekommunikation","Tierbedarf & Tiernahrung","Babyausstattung für Mama & Kind","Gastronomie","Vergleichsportale","Wohnen / Möbel & Accessoires","Spenden/Non-Profit","Reisen","Events/Veranstaltungen","Sonstiges"],
    limbic:["Stimulanz","Dominanz","Balance","Kontrolle/Disziplin"],
    pagetype:["Global","Startseite","Kategorieseite","Produktübersichtsseite","Produktdetailseite","Warenkorb","Check-Out","Konfigurator","Vergleichs- oder Beitragsrechner","Layer","Kundenkonto","Login-Seite","FAQ'S","Service & Kontakt","Über uns","So funktioniert's","Footer","Newsletter","E-Mail","Menü / Navigation","Cookiemeldung","Paket","Offline"],
    device:["Desktop","Mobile","Offline"]
}

export let experimentFilters = {
    patterns:["Reason Why","Social Proof","Scarcity","Kognitive Leichtigkeit","Paradox of Choice","Primacy-Recency Effect","Flow","Order and Harmony","Implicit Signals","Framing","Price Perception","Zero-Risk Bias","Trust","Curiosity","Value","Contrast Effect","Loss Aversion","Known","Illusion of Control","Sympathy","Cheering","Status Quo Bias","Anchoring","Preview","Story-Telling","Testimonials","Consistency/Commitment","Nonsense Math Effect","Mere Exposure Effect","Breaking Rules","Authority","Inner Dialogue","Faces and Views","Halo Effect","Emotional Resonance","Completion","Banner Blindness","Feedback","Reward","Unlocking","Authenticity","Availability Heuristics","Hope","Decoy Effect","Caring","Endowment Effect","Success","Free","Reciprocity","Patterns and Principles","Kognitive Dissonanz","Diderot Effect","Default","Mirroring","Mental Models","Sensation"],
    pagetypes:["Produktdetailseite","Kategorieseite","Warenkorb","diverse","Global","Checkout","Produktkonfiguration","Startseite","Landingpage","Bestätigungsseite","Gateway","Suchergebnisseite","Anzeigenseite","Antragsprozess","Händlerseite","Content","Support"],
    devices:["Desktop","Mobile","Tablet"],
    goals:["Kaufabschluss","Pageview","Revenue","Warenkorb","Interaktion","Lead","Anmeldung","Bouncerate","Registrierung","Revenue per Visitor"],
    significant:["Ja","Nein"],
    uplift:["Positiv","Kein Effekt","Negativ"]
}

/*"industry":["Fashion & Accessoires","Consumer Goods","DIY, Craft Goods & Garden","Electronics","Media","Automotive","Travel & Holidays","Other","Service","Finance & Insurance","Real Estate","Food","Health Care","Software","Industry Goods","Generalists"],*/