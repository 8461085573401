import Vue from 'vue'

export const socketModule = {
  namespaced: true,
  state: {
    isConnected: false,
    reconnectError: false,
    activeUsers: {
      shoutOuts: [],
      users: [],
    },
    scraperUpdate: {},
    message: '',
    shoutOut: {
      userId: "",
      text: "",
    },
    shoutOutTimer: 0,
    ping: "",
    pingTimer: 0,
    heartbeat: null,
    route: "/",
  },
  getters: {},
  mutations: {
    SOCKET_ONOPEN(state, event, data) {
      console.debug('onopen', event, data)
      Vue.prototype.$socket = event.currentTarget;
      state.isConnected = true;

      function heartbeat() {
        console.debug('bumbum');
        clearTimeout(state.heartbeat);
        state.heartbeat = setTimeout(() => {
          Vue.prototype.$socket.close();
        }, 30000 + 1000);
      }

      heartbeat();
    },
    SOCKET_ONCLOSE(state, event) {
      console.debug('onclose', event)
      state.isConnected = false
      clearTimeout(state.heartbeat);
    },
    SOCKET_ONERROR(state, event) {
      console.error('error', event)
    },
    SOCKET_ONPING(state, message) {
      console.debug('onping', message)

      function heartbeat() {
        console.debug('bumbum');
        clearTimeout(state.heartbeat);
        state.heartbeat = setTimeout(() => {
          Vue.prototype.$socket.close();
        }, 30000 + 1000);
      }

      heartbeat();
    },
    SOCKET_ONPONG(state, message) {
      console.debug('onpong', message)
      Vue.prototype.$socket.sendObj({action: 'ping'})
    },
    SOCKET_ONMESSAGE(state, msg) {
      console.debug('message', msg)
      state.message = msg

      switch (msg.action) {
        case 'setActiveUsers':
          console.debug('setActiveUsers', msg);
          state.activeUsers = msg.activeUsers;
          Vue.prototype.$forceUpdate();
          break;
        default:
          console.debug('default', msg);
      }
    },
    SOCKET_RECONNECT(state, count) {
      console.debug('reconnect', count);
    },
    SOCKET_RECONNECT_ERROR(state) {
      console.debug('reconnect error')
      state.reconnectError = true;
    },
  },
  actions: {
    getUserData(context) {
      let user = context.rootGetters.getUser;
      let project = context.rootGetters.getProject;
      Vue.prototype.$socket.sendObj({action: 'setUser', projectId: project.projectId, user, route: context.state.route});
    },
    sendMessage(context, message) {
      console.debug('sendMessage', message);
      context.state.message = message;
      Vue.prototype.$socket.sendObj(message);
    },
    setActiveUsers(context, data) {
      console.debug(data);
      context.state.activeUsers = data.activeUsers;
      Vue.prototype.$forceUpdate();
    },
    socketPing(context, data) {
      console.debug('socketPing', data);
      if (context.state.pingTimer) return;
      context.state.ping = data.sender;
      Vue.prototype.$forceUpdate();
      context.state.pingTimer = setTimeout(() => {
        context.state.ping = false;
        context.state.pingTimer = 0;
      }, 3000);
    },
    socketShoutOut(context, data) {
      console.debug('socketShoutOut', data);
      clearTimeout(context.state.shoutOutTimer);
      context.state.shoutOut = data.shoutOut;
      Vue.prototype.$forceUpdate();
      context.state.shoutOutTimer = setTimeout(() => {
        context.state.shoutOut = {userId: "", text: ""};
      }, 10000);
    },
    projectUpdated(context, data) {
      console.debug('projectUpdated', data);
      let project = data.project;

      for (let prop of Object.keys(project)) {
        if (prop === 'notifications') continue;
        if (JSON.stringify(project[prop]) === JSON.stringify(context.rootGetters.getProject[prop])) {
          context.rootState.project.notifications[prop] = "";
        } else {
          context.rootState.project.notifications[prop] = "1";
        }
      }
      context.commit('project/mergeProjectData', project, {root: true});
      Vue.prototype.$forceUpdate();
    },
    userUpdated(context, data) {
      console.debug('userUpdated', data);
      let user = data.user;
      context.commit('user/mergeUserData', user, {root: true});
      Vue.prototype.$forceUpdate();
    },
    scraperStatusUpdated(context, data) {
      console.debug('scraperStatusUpdated', data);
      let scraperUpdate = data.scraperUpdate;

      if (scraperUpdate.scrape) {
        context.commit('project/mergeScrapeData', scraperUpdate.scrape, {root: true});
      }
      if (scraperUpdate.msg === "Scrape beendet") {
        context.rootState.project.scraper.status = ["finished"];
      } else {
        context.rootState.project.scraper.status = ["running"];
      }
      context.state.scraperUpdate = data.scraperUpdate;
      Vue.prototype.$forceUpdate();
    },
  }
}
