<template>
  <div>
    <nav v-if="notifications" id="nav" class="navbar px-0 pt-1 mb-4 justify-content-center">
      <div class="nav-bg" :style="'background:' + project.settings.themeColor"></div>
      <ul :class="hideLinkTexts ? 'text-links-hidden' : ''" class="nav nav-pills justify-content-center">
        <li v-for="(group, index) in routes.filter(g => !g.hidden)" class="nav-item" :key="index">
          <p class="small text-muted text-center mb-1">{{ group.title }}</p>
          <div class="btn-group mb-3 mx-3" :class="group.filtered && activeTheoryId ? 'filtered' : ''">
            <router-link v-for="(route, index) in group.links.filter(l => !l.hidden && (isAdvanced || !l.advanced))"
                         :to="route.route"
                         active-class="active"
                         class="btn btn-link nav-link"
                         :key="'g' + index"
            >
              <i :class="route.icon" class="fad fa-fw"></i>
              <span class="link-text"> {{ route.title }}</span>
              <span v-if="notifications[route.prop]" class="badge badge-pill badge-danger">{{ notifications[route.prop] }}</span>
              <span class="user-indicators">
                <span v-for="(user, index) in routeUsers[route.prop]"
                      :key="index"
                      :style="'background:' + user.settings.chatColor"
                      class="user-indicator"
                ></span>
              </span>
            </router-link>
          </div>
        </li>
        <li class="nav-item expand-icon mt-3 pt-3 pr-1" @click="hideLinkTexts = !hideLinkTexts">
          <i :class="hideLinkTexts ? 'fa-angle-double-down' : 'fa-angle-double-up'" class="fas text-primary mr-3"></i>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Nav',
  data() {
    return {
      hideLinkTexts: true,
      hideLinkTimeout: 3000,
    }
  },
  computed: {
    notifications() {
      return this.project.notifications;
    },
    routeUsers() {
      let routes = {};
      this.routes.forEach(route => {
        routes[route.prop] = this.socket.activeUsers ? this.socket.activeUsers.users.filter(u => u.route === route.route) : [];
      });
      return routes;
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  padding: 0;

  .nav-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .15;
    z-index: 0;
    transition: all .5s;
  }

  /*.nav:hover {
    flex-wrap: nowrap;
    flex-shrink: 0;
    overflow: hidden;
  }*/
}

.expand-icon {
  cursor: pointer;
/*
  position: absolute;
  top: .3rem;
  left: 1rem;
  z-index: 1;
*/
}

.nav-item {
  position: relative;

  .btn-group {
    border-radius: 3px;
    box-shadow: none;

    &.filtered {
      box-shadow: 0 0 0 .2rem var(--primary-light);
    }
  }

  $border-width: 2px;
  $border-color: rgba(0,0,0,.05);

  .btn {
    background: var(--white);
    border-top: transparent;
    border-bottom: $border-width solid $border-color;
    transition: background .5s, color .5s, border .1s;

    &:hover {
      background: var(--light);
      border-top: $border-width solid $border-color;
      border-bottom: transparent;
    }
    &.active {
      background: var(--primary);
    }
  }

  .badge {
    position: absolute;
    top: -.2rem;
    right: -.1rem;
    transition: all .2s;
    opacity: .3;
    z-index: 2;
  }

  .active .badge {
    top: -.5rem;
    right: -.5rem;
    opacity: 1;
  }

  .link-text {
    transition: all .2s;
  }
}

.text-links-hidden .nav-item .nav-link:not(.active) .link-text {
  color: transparent;
  font-size: 0;
}

.user-indicators {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -.2rem;
  left: -.1rem;
  right: -.1rem;
  transition: all .2s;
  opacity: .8;
}
</style>