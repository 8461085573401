<template>
  <div id="app">

    <Header></Header>

    <AppNav v-if="isLoggedIn && $route.path !== '/setup'"></AppNav>

    <div id="app-wrapper" class="container-fluid">
      <div v-if="!blockUI" id="app-content">
        <router-view/>
      </div>
      <div v-else class="row justify-content-center">
        <div class="col-lg-8">
          <div class="card p-5 mx-auto mt-4 text-center">
            <i class="fad fa-clock fa-5x text-primary"></i>
            <h3 class="mt-4">
              Lade Daten...
            </h3>
            <p>
              Wenn dein letzter Scrape (bzw. deine Seite) sehr groß war, kann es eine Weile dauern die Daten zu laden. <br>
              Bitte warte noch kurz.
            </p>
          </div>
        </div>
      </div>
      <div v-show="dataRequestPending" class="loading-widget">
        <div class="icon-wrapper">
          <i class="fad fa-circle-notch fa-5x fa-spin text-primary"></i>
        </div>
      </div>

      <div class="footer mb-5">
        <hr>
        <p class="small text-muted">&copy; 2021 Fuerst.one E-Commerce & Web Strategy |
          <a href="https://fuerst.one/impressum" target="_blank">Impressum</a> |
          <a href="https://fuerst.one/datenschutz" target="_blank">Datenschutz</a> |
          <a href="https://www.buymeacoffee.com/fuerst.one">Buy me an apple</a>
        </p>
      </div>

      <div v-if="isAdmin ^ adminOverride" class="admin-switch">
        <p class="small text-muted mb-2">
          <i :class="adminOverride ? 'fa-toggle-off' : 'fa-toggle-on'" class="fad fa-lg" @click="toggleAdminOverride"></i>
          Du bist "{{ user.nickname }}" und befindest dich in Projekt "{{ project.profile.projectName }}" im Modul "{{ $route.name }}" -
          <router-link to="/debug"><i class="fad fa-bug"></i> Debug</router-link>
        </p>
      </div>

      <CollabOverlay></CollabOverlay>
    </div>
  </div>
</template>

<script>
import AppNav from "@/components/Nav.vue"
import CollabOverlay from "@/components/CollabOverlay";
import Header from "@/components/Header";

export default {
  name: 'App',
  components: {
    Header,
    CollabOverlay,
    AppNav
  },
  data() {
    return {}
  },
  watch: {
    "project.settings.themeColor": {
      deep: true,
      handler(newVal) {
        newVal = newVal || '#505050';
        let root = document.documentElement;
        root.style.setProperty("--primary", newVal);
        let newValDark = this.pSBC(-0.3, newVal)
        root.style.setProperty("--primary-dark", newValDark);
        root.style.setProperty("--primary-light", newValDark + "33");
      }
    }
  },
  computed: {
  },
  methods: {
  },
  mounted() {
    if (!this.isLoggedIn) {
      this.$store.dispatch('auth/init');
    }
  },
  beforeRouteUpdate(to) {
    this.socket.route = to.path;
  }
}
</script>

<style lang="scss">
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #8f8f8f;
  --gray-dark: #343a40;
  --primary: #007bff;
  --primary-dark: #0159b8;
  --primary-light: #007bff55;
  --secondary: #8f8f8f;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --black: #202020;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-x2: 1680px;
  --breakpoint-x3: 1920px;
}

@media (min-width: 1200px) {
  #app-wrapper {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1680px) {
  .container, .container-lg, .container-md, .container-sm {
    max-width: 1640px;
  }
  #app-wrapper {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
@media (min-width: 1920px) {
  .container, .container-lg, .container-md, .container-sm {
    max-width: 1880px;
  }
  #app-wrapper {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

* {
  &:not(:disabled):not(.disabled) {
    &:focus, &:focus-within, &:focus-visible, :active, &.active {
      outline-color: var(--primary-light);
    }
  }
}

.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);

  &:hover, &:focus, &:disabled, &.disabled, &:not(:disabled):not(.disabled):active, &.active {
    background-color: var(--primary-dark);
    border-color: var(--primary-dark);
  }

  &:focus, &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem var(--primary-light);
  }
}
.btn-outline-primary {
  border-color: var(--primary);
  color: var(--primary);
  &:hover, &:focus, &:disabled, &.disabled, &:not(:disabled):not(.disabled):active, &.active {
    background-color: var(--primary-dark);
    border-color: var(--primary-dark);
    color: var(--white);
  }
  &:focus, &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem var(--primary-light);
  }
}
.badge-primary, a.badge-primary, .dropdown-item.active, .dropdown-item:active {
  background-color: var(--primary);
  &:hover, &:focus, &:active {
    background-color: var(--primary-dark);
  }
}
.bg-primary {
  background-color: var(--primary) !important;
  &:hover, &:focus, &:active {
    background-color: var(--primary-dark) !important;
  }
}
.alert-primary {
  background-color: var(--primary-light);
  border-color: var(--primary-light);
  color: var(--primary-dark);
}
.progress-bar {
  background-color: var(--primary);
  &:hover, &:focus, &:active {
    background-color: var(--primary);
  }
}
.border-primary {
  border-color: var(--primary) !important;
  &:hover, &:focus, &:active {
    border-color: var(--primary-dark) !important;
  }
}
.btn-link, .text-primary, a {
  color: var(--primary);
  &:hover, &:focus, &:active {
    color: var(--primary-dark);
  }
}
.text-primary {
  color: var(--primary) !important;
}
.btn:disabled, .btn.disabled {
  cursor: default;
}

body {
  background: var(--white);
  color: var(--dark);
}

/*.card, .card-body, .card-header, .card-footer {
  border-color: rgba(0,0,0,.1);
}
.card, .card-body, input, select {
  background-color: var(--white);
}
.card-header, .card-footer {
  background-color: var(--light);
}*/

.clickable {
  cursor: pointer;
}

input.naked {
  border: none;
  background: transparent;
}

.img-wrapper {
  max-height: 15rem;
  overflow-y: scroll;
}

.avatar-wrapper {
  width: 66%;
  height: 0;
  padding-top: 66%;
  overflow: hidden;
  border-radius: 100%;
  text-align: center;
  position: relative;
  margin: 1rem auto;

  .avatar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.color-orb {
  border-radius: 100%;
  height: 0;
  width: 100%;
  padding-top: 100%;
}

.scroll-container {
  max-height: 25rem;
  overflow-y: scroll;
  }

  .scroll-container-v {
    max-height: 15rem;
    overflow-y: scroll;
  }

  .scroll-container-h {
    overflow-x: scroll;
  }

  .loading-widget {
    position: fixed;
    top: 5rem;
    right: 2rem;

    .icon-wrapper {
      position: relative;

      i.fa-spin {
        font-size: 2rem;
      }
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
  .edit-button, .remove-button, .slot-button {
    position: absolute;
    top: -1rem;
    width: 2rem;
    height: 2rem;
    border-radius: 4px;
    background: var(--dark);
    text-align: center;
    font-size: 1rem;
    padding: .25rem;
    cursor: pointer;

    &:hover {
      color: var(--white);
      background: var(--black);
    }

    &, a {
      color: var(--white);
    }
  }

.remove-button {
  right: -1rem;
}
.edit-button {
  right: 1.5rem;
}
.slot-button {
  right: 4rem;
}

.user-indicator {
  display: inline-block;
  height: .4rem;
  width: .4rem;
  margin: .1rem;
  border-radius: 100%;
  vertical-align: middle;
}
</style>
