<template>
  <div id="signup-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead mb-5">
      {{ activeRoute.description }}
    </p>

    <form v-if="step === 0" onsubmit="return false" class="row justify-content-center">
      <div class="col-sm-10 col-md-8 col-lg-5">
        <h3 class="text-center">Dein Weg zum Erfolg</h3>
        <p class="text-center">
          Starte jetzt durch.
        </p>

        <hr>

        <div class="form-group">
          <label for="email">E-Mail:</label>
          <input id="email"
                 type="email"
                 v-model="newUser.email"
                 class="form-control"
                 placeholder="deine@email.de"
                 autocomplete="username"
                 required
          >
        </div>

        <div class="form-group">
          <label for="password">Passwort:</label>
          <input id="password"
                 type="password"
                 v-model="newUser.password"
                 class="form-control"
                 placeholder="Dein P4ssw0rt"
                 autocomplete="new-password"
                 required
          >
        </div>
        <div class="form-group">
          <label for="repeat-password">Passwort wiederholen:</label>
          <input id="repeat-password"
                 type="password"
                 class="form-control"
                 placeholder="Passwort wiederholen"
                 autocomplete="none"
                 required
                 @change="checkPassword($event)"
          >
        </div>

        <div v-if="!failMsg && !success" class="row align-items-center my-4">
          <div class="col-auto text-center p-0">
            <i class="fad fa-check-circle fa-2x text-primary ml-4"></i>
          </div>
          <div class="col">
            <p class="text-left mb-1">
              <strong>Get ready.</strong><br>
              Halte einen Zugang zu deinem Server bereit, damit du gleich durchstarten kannst.
            </p>
          </div>
        </div>
        <div v-if="failMsg" class="row align-items-center my-4">
          <div class="col-auto text-center p-0">
            <i class="fad fa-times-circle fa-2x text-danger ml-4"></i>
          </div>
          <div class="col">
            <p class="text-left mb-1">
              <strong>Sorry.</strong><br>
              {{ failMsg }}
            </p>
          </div>
        </div>
        <div v-if="success" class="row align-items-center my-4">
          <div class="col-auto text-center p-0">
            <i class="fad fa-check-circle fa-2x text-success ml-4"></i>
          </div>
          <div class="col">
            <p class="text-left mb-1">
              <strong>Super.</strong><br>
              Du wirst jeden Moment weitergeleitet.
            </p>
          </div>
        </div>

        <button class="btn btn-primary btn-block mt-3 mb-5" @click="signUp">Anmelden</button>

        <div v-if="projects.length" class="row align-items-center my-4">
          <div class="col-auto text-center p-0">
            <i class="fad fa-arrow-circle-right fa-2x text-success ml-4"></i>
          </div>
          <div class="col">
            <p class="text-left mb-1">
              Schöne Grüße von {{ projects.map(p => p.profile.projectName).join(', ') }}
            </p>
          </div>
        </div>
      </div>
    </form>

  </div>
</template>

<script>
const userTemplate = require('../../backend/templates/user.template');

export default {
  name: 'Signup',
  components: {},
  data() {
    return {
      step: 0,
      failMsg: "",
      success: false,
      newUser: {
        ...userTemplate,
        id: this.generateId(),
        createdAt: new Date(),
      }
    }
  },
  computed: {
    projects() {
      return this.project.projectSelection || [];
    },
  },
  watch: {},
  methods: {
    checkPassword($event) {
      if ($event.target.value !== this.newUser.password) {
        this.failMsg = "Die Passwörter stimmen nicht überein.";
      } else {
        this.failMsg = "";
      }
    },
    checkInvite() {
      this.$store.dispatch('auth/checkInvite', this.$route.params.id)
          .then(res => {
            console.debug('Invite checked', res);
          });
    },
    signUp() {
      this.newUser.createdAt = new Date();
      this.$store.commit('user/mergeUserData', this.newUser);
      this.failMsg = "";
      this.success = false;
      this.$store.dispatch('auth/signUp', this.newUser)
          .then(res => {
            console.debug('Signed Up', res);
            this.$router.push('/setup');
          })
          .catch(err => {
            if (err.indexOf("status code 400") >= 0) {
              this.failMsg = "Du bist schon registriert. Du wirst nun zum Login weitergeleitet.";
              setTimeout(() => {
                this.$router.push('/login');
              }, 1000);
            } else {
              this.failMsg = "Ein Fehler ist aufgetreten. Versuche es später erneut oder kontaktiere alexander@fuerst.one";
            }
          });
    },
  },
  mounted() {
    this.$store.commit('user/removeUserData');
    this.$store.commit('project/removeProjectData');
    this.$store.commit('project/removeScrapeData');
    if (this.$route.params.id) {
      this.checkInvite();
    }
  }
}
</script>
