import { api } from './api'

export const uploadModule = {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    /*{
      files: <Files>,
      path: <String>
    }*/
    uploadFiles(context, data) {
      console.log('uploadFiles update', data);
      return new Promise((resolve, reject) => {
        if (context.rootState.dataRequestPending) {
          reject('another request is still pending');
        }
        if (!context.rootState.auth.loggedIn) {
          reject('not logged in');
        }

        context.commit('setRequestState', true, {root: true});

        api.post(`/api/upload`, data)
            .then(res => {
              console.log('upload res', res);
              context.commit('setRequestState', false, {root: true});
              resolve({status: res.status, msg: res.statusText, files: res.data.files});
            })
            .catch(err => {
              console.log('upload err', err);
              context.commit('setRequestState', false, {root: true});
              reject({status: err.status, msg: err});
            });
      });
    },
  }
}
