import Vue from 'vue'
import Vuex from 'vuex'

import { authModule } from './auth'
import { socketModule } from './socket'
import { userModule } from './user'
import { projectModule } from './project'

import {guide} from "@/assets/import-guide.min"
import {experimentFilters, patternFilters, practiceFilters,} from "@/assets/import-filters"
import {uploadModule} from "@/store/upload";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dataRequestPending: true,
    blockUI: false,
    adminOverride: false,

    guide,
    patternFilters,
    practiceFilters,
    experimentFilters,
    limbicMap: {
      tags: [
        {
          name: "Impulsivität",
          r: 0,
          d: 70
        },
        {
          name: "Spontaneität",
          r: 0,
          d: 40
        },
        {
          name: "Rebellion",
          r: 6,
          d: 78
        },
        {
          name: "Mut",
          r: 6,
          d: 64
        },
        {
          name: "Autonomie",
          r: 9,
          d: 42
        },
        {
          name: "Sieg",
          r: 10,
          d: 75
        },
        {
          name: "Kampf",
          r: 12,
          d: 68
        },
        {
          name: "Macht",
          r: 13,
          d: 80
        },
        {
          name: "Freiheit",
          r: 14,
          d: 50
        },
        {
          name: "Ruhm",
          r: 15,
          d: 60
        },
        {
          name: "Elite",
          r: 16,
          d: 80
        },
        {
          name: "Durchsetzung",
          r: 18,
          d: 75
        },
        {
          name: "Status",
          r: 18,
          d: 60
        },
        {
          name: "Stolz",
          r: 18,
          d: 50
        },
        {
          name: "Ehre",
          r: 21,
          d: 50
        },
        {
          name: "Leistung",
          r: 21,
          d: 78
        },
        {
          name: "Effizienz",
          r: 22,
          d: 68
        },
        {
          name: "Ehrgeiz",
          r: 24,
          d: 75
        },
        {
          name: "Fleiß",
          r: 24,
          d: 58
        },
        {
          name: "Funktionalität",
          r: 26,
          d: 37
        },
        {
          name: "Hartnäckigkeit",
          r: 26,
          d: 73
        },
        {
          name: "Logik",
          r: 29,
          d: 57
        },
        {
          name: "Präzision",
          r: 28,
          d: 79
        },
        {
          name: "Ordnung",
          r: 32,
          d: 42
        },
        {
          name: "Disziplin",
          r: 31,
          d: 68
        },
        {
          name: "Pflicht",
          r: 34,
          d: 65
        },
        {
          name: "Askese",
          r: 35,
          d: 74
        },
        {
          name: "Moral",
          r: 35,
          d: 55
        },
        {
          name: "Gerechtigkeit",
          r: 36,
          d: 42
        },
        {
          name: "Hygiene",
          r: 37,
          d: 58
        },
        {
          name: "Sparsamkeit",
          r: 38,
          d: 74
        },
        {
          name: "Gehorsamkeit",
          r: 42,
          d: 43
        },
        {
          name: "Verlässlichkeit",
          r: 42,
          d: 65
        },
        {
          name: "Qualität",
          r: 42,
          d: 75
        },
        {
          name: "Sauberkeit",
          r: 43,
          d: 52
        },
        {
          name: "Gesundheit",
          r: 46,
          d: 64
        },
        {
          name: "Tradition",
          r: 46,
          d: 72
        },
        {
          name: "Treue",
          r: 47,
          d: 47
        },
        {
          name: "Geborgenheit",
          r: 49,
          d: 55
        },
        {
          name: "Sicherheit",
          r: 50,
          d: 72
        },
        {
          name: "Familie",
          r: 53,
          d: 47
        },
        {
          name: "Heimat",
          r: 54,
          d: 60
        },
        {
          name: "Nostalgie",
          r: 54,
          d: 75
        },
        {
          name: "Freundschaft",
          r: 53,
          d: 34
        },
        {
          name: "Natur",
          r: 58,
          d: 75
        },
        {
          name: "Vertrauen",
          r: 59,
          d: 58
        },
        {
          name: "Geselligkeit",
          r: 60,
          d: 70
        },
        {
          name: "Herzlichkeit",
          r: 60,
          d: 44
        },
        {
          name: "Flexibilität",
          r: 62,
          d: 37
        },
        {
          name: "Toleranz",
          r: 66,
          d: 30
        },
        {
          name: "Sinnlichkeit",
          r: 64,
          d: 75
        },
        {
          name: "Poesie",
          r: 65,
          d: 55
        },
        {
          name: "Offenheit",
          r: 68,
          d: 45
        },
        {
          name: "Träumen",
          r: 67,
          d: 70
        },
        {
          name: "Genuss",
          r: 71,
          d: 80
        },
        {
          name: "Fantasie",
          r: 72,
          d: 60
        },
        {
          name: "Leichtigkeit",
          r: 74,
          d: 30
        },
        {
          name: "Humor",
          r: 77,
          d: 60
        },
        {
          name: "Spaß",
          r: 80,
          d: 78
        },
        {
          name: "Neugier",
          r: 81,
          d: 50
        },
        {
          name: "Kunst",
          r: 83,
          d: 70
        },
        {
          name: "Abwechslung",
          r: 88,
          d: 40
        },
        {
          name: "Kreativität",
          r: 88,
          d: 75
        },
        {
          name: "Individualismus",
          r: 91,
          d: 52
        },
        {
          name: "Extravaganz",
          r: 93,
          d: 80
        },
        {
          name: "Risikofreude",
          r: 97,
          d: 53
        },
      ],
      labels: [
        {
          name: "Abenteuer/Thrill",
          r: 0,
          d: 98
        },
        {
          name: "Dominanz",
          r: 16,
          d: 98
        },
        {
          name: "Disziplin/Kontrolle",
          r: 34,
          d: 98
        },
        {
          name: "Balance",
          r: 50,
          d: 98
        },
        {
          name: "Fantasie/Genuss",
          r: 66,
          d: 98
        },
        {
          name: "Stimulanz",
          r: 84,
          d: 98
        },
      ]
    },
    platforms: {
      Amazon: {
        title: "Amazon",
        color: "#ff9900"
      },
      Behance: {
        title: "Behance",
        color: "#1769ff"
      },
      "Booking.com": {
        title: "Booking.com",
        color: "#003580"
      },
      Clubhouse: {
        title: "Clubhouse",
        color: "#f0f0f0"
      },
      Dailymotion: {
        title: "Dailymotion",
        color: "#00aaff"
      },
      Deezer: {
        title: "Deezer",
        color: "#ff0000"
      },
      DeviantArt: {
        title: "DeviantArt",
        color: "#05cc47"
      },
      Dribbble: {
        title: "Dribbble",
        color: "#444444"
      },
      Ebay: {
        title: "Ebay",
        color: "#e53238"
      },
      Etsy: {
        title: "Etsy",
        color: "#d5641c"
      },
      Facebook: {
        title: "Facebook",
        color: "#3b5998"
      },
      GitHub: {
        title: "GitHub",
        color: "#4078c0"
      },
      GitLab: {
        title: "GitLab",
        color: "#fca326"
      },
      Instagram: {
        title: "Instagram",
        color: "#e1306c"
      },
      LinkedIn: {
        title: "LinkedIn",
        color: "#0077b5"
      },
      Pinterest: {
        title: "Pinterest",
        color: "#e60023"
      },
      Reddit: {
        title: "Reddit",
        color: "#ff4500"
      },
      Spotify: {
        title: "Spotify",
        color: "#1db954"
      },
      Telegram: {
        title: "Telegram",
        color: "#0088cc"
      },
      TikTok: {
        title: "TikTok",
        color: "#ff009d"
      },
      Twitter: {
        title: "Twitter",
        color: "#1da1f2"
      },
      Website: {
        title: "Website",
        color: "#333333"
      },
      WhatsApp: {
        title: "WhatsApp",
        color: "#075e54"
      },
      XING: {
        title: "XING",
        color: "#026466"
      },
      YouTube: {
        title: "YouTube",
        color: "#ff0000"
      },
    },
    templateTheories: [
      {
        id: "Cheering",
        title: "Cheering",
        description: "Nutzer reagieren positiv auf Lob und Anerkennung für die Interaktionen auf deiner Website.",
        icon: "fa-heart",
        color: "#d08f00",
        priority: 0,
        template: "Cheering",
        examples: [],
        assumptions: [],
        validations: [],
      },
      {
        id: "Reason Why",
        title: "Reason Why",
        description: "Nutzer fühlen sich mit einer Begründung weitaus sicherer bei einer Interaktion.",
        icon: "fa-heart",
        color: "#52a700",
        priority: 0,
        template: "Reason Why",
        examples: [],
        assumptions: [],
        validations: [],
      }
    ],
  },
  getters: {
    getUser(state, getters) {
      return getters['user/getUser'];
    },
    getProject(state, getters) {
      return getters['project/getProject'];
    },
  },
  mutations: {
    toggleAdminOverride(state) {
      state.adminOverride = !state.adminOverride;
    },
    setRequestState(state, val) {
      state.dataRequestPending = val;
    },
    setBlockUI(state, val) {
      state.blockUI = val;
    },
  },
  actions: {},
  modules: {
    auth: authModule,
    socket: socketModule,
    user: userModule,
    project: projectModule,
    upload: uploadModule
  }
})
