let projectTemplate = {
  profile: {
    projectName: "",
    logo: "",
    contactEmail: "",
    salesEmail: "",
    firstName: "",
    lastName: "",
    telephone: "",
    address: {
      name: "",
      street: "",
      additionalField: "",
      city: "",
      zipCode: "",
      region: "",
      country: "",
    },
    revenue: 0,
    branch: "",
    rootUrl: "",
    createdAt: new Date(),
  },
  members: [],
  accountType: "",
  externalNodes: [],
  canvas: [],
  jobs: [],
  personas: [],
  journeys: [],
  audits: [],
  findings: [],
  hypotheses: [],
  brainstorm: {
    title: "",
    children: [],
    links: [],
    keys: [],
    looseItems: [],
  },
  ideas: [],
  learnings: [],
  experiments: [],
  nodeMetrics: [],
  nodeAttributes: [],
  nodePagetypes: [],
  calculations: [
    {
      currency: "€",
      scale: "Jahr(e)",
      funnelSteps: [
        {
          title: "Views",
          visits: 30000,
          visitsUnique: 30000,
        },
        {
          title: "Visits",
          visits: 100000,
          visitsUnique: 50000,
        },
        {
          title: "Warenkörbe",
          visits: 5000,
          visitsUnique: 5000,
        },
        {
          title: "Abschlüsse",
          visits: 1000,
          visitsUnique: 1000,
        },
        {
          title: "Umsatz",
          visits: 10000,
          visitsUnique: 10000,
          type: "currency"
        },
        {
          title: "Gewinn",
          visits: 5000,
          visitsUnique: 5000,
          type: "currency"
        },
      ],
      adClicks: 2000,
      visits: 100000,
      carts: 5000,
      orders: 1000,
      revenue: 10000,
      earnings: 5000,
      projectCost: 5000,
      conversionRate: 1,
      valuePerConversion: 10,
      earningsRatio: 50,
      conversionUplift: 10,
    }
  ],
  slots: [],
  notes: [],
  scraper: {
    id: "",
    fullScrapeFrequency: 14,
    singleScrapeFrequency: 1,
    full: [],
    single: [],
    status: ["idle"],
  },
  settings: {
    inviteActive: false,
    themeColor: '#505050',
    nodePagetypes: [
      {id:"Startseite",title:"Startseite"},
      {id:"Landingpage",title:"Landingpage"},
      {id:"Kategorieseite",title:"Kategorieseite"},
      {id:"Produktdetailseite",title:"Produktdetailseite"},
      {id:"Warenkorb",title:"Warenkorb"},
      {id:"Checkout",title:"Checkout"},
      {id:"Danke-Seite",title:"Danke-Seite"},
      {id:"Nutzer-Account",title:"Nutzer-Account"},
      {id:"Login",title:"Login"},
      {id:"Kontakt",title:"Kontakt"},
      {id:"Über uns",title:"Über uns"},
      {id:"Blog-Artikel",title:"Blog-Artikel"},
    ],
    nodeAttributes: [
      {id:"Ziele",title:"Ziele"},
      {id:"Offene Fragen",title:"Offene Fragen"}
    ],
    nodeMetrics: [
      {id:"Visits",title:"Visits"},
      {id:"Bounce-Rate",title:"Bounce-Rate"},
      {id:"% Ausstiege",title:"% Ausstiege"}
    ],
    tags: [],
    topics: [],
    contentPlatforms: [],
  },
  notifications: {
    dashboard: "",
    guide: "",
    profile: "",
    theories: "",
    canvas: "",
    jobs: "",
    personas: "",
    scraper: "",
    links: "",
    contents: "",
    journeys: "",
    campaigns: "–",
    channels: "–",
    kpis: "–",
    benchmarks: "–",
    audits: "",
    findings: "",
    hypotheses: "",
    ideas: "",
    brainstorm: "",
    experiments: "",
    learnings: "",
    calculations: "",
    slots: "",
    notes: "",
    todos: "",
    patterns: "",
    practices: "",
  },
  deleted: 'false',
}


module.exports = projectTemplate;