import Vue from 'vue'
import VueRouter from 'vue-router'
/*import Home from '../views/Home.vue'*/
import Login from "@/views/Login";
import Logout from "@/views/Logout";
import Signup from "@/views/Signup";
import Guide from "../views/Guide";
import Overview from "@/views/Overview";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {title: 'Home'},
    component: Overview
  },
  {
    path: '/login',
    name: 'Log in',
    meta: {title: 'Log in'},
    component: Login
  },
  {
    path: '/logout',
    name: 'Log out',
    meta: {title: 'Log out'},
    component: Logout
  },
  {
    path: '/signup',
    name: 'Sign Up',
    meta: {title: 'Sign Up'},
    component: Signup
  },
  {
    path: '/signup/:id',
    name: 'You\'re Invited',
    meta: {title: 'Invite'},
    component: Signup
  },
  {
    path: '/setup',
    name: 'Project Setup',
    meta: {title: 'Project Setup'},
    component: () => import('../views/Setup.vue')
  },
  {
    path: '/dashboard',
    name: 'Toolbox Dashboard',
    meta: {title: 'Toolbox Dashboard'},
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/overview',
    name: 'Toolbox Overview',
    meta: {title: 'Toolbox Overview'},
    component: Overview
  },
  {
    path: '/guide',
    name: 'Web Strategy Guide',
    meta: {title: 'Web Strategy Guide'},
    component: Guide
  },
  {
    path: '/profile',
    name: 'Profil',
    meta: {title: 'Profil'},
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/projects',
    name: 'Project Overview',
    meta: {title: 'Projects Overview'},
    component: () => import('../views/Projects.vue')
  },
  {
    path: '/project/create',
    name: 'Create Project',
    meta: {title: 'Create Project'},
    component: () => import('../views/ProjectsAdd.vue')
  },
  {
    path: '/project/:id',
    name: 'Project Detail',
    meta: {title: 'Projects Detail'},
    component: () => import('../views/ProjectsEdit.vue')
  },
  {
    path: '/debug',
    name: 'Debug',
    meta: {title: 'Debug'},
    component: () => import('../views/Debug.vue'),
  },
  {
    path: '/brainstorm/:tool',
    meta: {title: 'Brainstorming Tool'},
    component: () => import('../views/Brainstorm.vue'),
  },
  {
    path: '/brainstorm/:id/:tool',
    meta: {title: 'Brainstorming Tool'},
    component: () => import('../views/Brainstorm.vue'),
  },
  {
    path: '/brainstorm',
    name: 'Brainstorming Tool',
    meta: {title: 'Brainstorming Tool'},
    component: () => import('../views/Brainstorm.vue'),
  },
  {
    path: '/canvas',
    name: 'Value Proposition Canvas',
    meta: {title: 'Value Proposition Canvas'},
    component: () => import('../views/Canvas.vue'),
  },
  {
    path: '/canvas/add',
    meta: { title: 'Edit Value Proposition Canvas' },
    component: () => import('../views/CanvasEdit.vue'),
  },
  {
    path: '/canvas/edit/:id',
    meta: { title: 'Edit Value Proposition Canvas' },
    component: () => import('../views/CanvasEdit.vue'),
  },
  {
    path: '/personas/add',
    meta: { title: 'Add Persona' },
    component: () => import('../views/PersonasEdit.vue'),
    children: [
      {
        path: '/personas/edit/:id',
        meta: { title: 'Edit Persona' },
        component: () => import('../views/PersonasEdit.vue'),
      }
    ]
  },
  {
    path: '/personas',
    name: 'Personas',
    meta: { title: 'Personas' },
    component: () => import('../views/Personas.vue'),
  },
  {
    path: '/jobs',
    name: 'Jobs to be Done',
    meta: { title: 'Jobs to be Done' },
    component: () => import('../views/Jobs.vue'),
  },
  {
    path: '/jobs/add',
    name: 'Add Job to be Done',
    meta: { title: 'Add Job to be Done' },
    component: () => import('../views/JobsEdit.vue'),
  },
  {
    path: '/jobs/edit/:id',
    name: 'Edit Job to be Done',
    meta: { title: 'Edit Job to be Done' },
    component: () => import('../views/JobsEdit.vue'),
  },
  {
    path: '/jobs/:id',
    name: 'Show Job to be Done',
    meta: { title: 'Job to be Done Detail' },
    component: () => import('../views/Jobs.vue'),
  },
  {
    path: '/scraper',
    name: 'Scraper',
    meta: { title: 'Link Tree Scraper' },
    component: () => import('../views/Scraper.vue'),
  },
  {
    path: '/scraper/single',
    name: 'ScraperSingle',
    meta: { title: 'Single Site Scraper' },
    component: () => import('../views/ScraperSingle.vue'),
  },
  {
    path: '/links',
    name: 'Link Tree',
    meta: { title: 'Link Tree' },
    component: () => import('../views/Links.vue'),
  },
  {
    path: '/links/add',
    name: 'Add Link Tree Node',
    meta: { title: 'Add Link Tree Node' },
    component: () => import('../views/LinksAdd.vue'),
  },
  {
    path: '/links/edit',
    name: 'Edit Link Tree',
    meta: { title: 'Edit Link Tree' },
    component: () => import('../views/LinksEdit.vue'),
  },
  {
    path: '/links/:id',
    name: 'Link Tree Node Detail',
    meta: {title: 'Link Tree'},
    component: () => import('../views/Links.vue'),
  },
  {
    path: '/contents',
    name: 'Content Pieces',
    meta: {title: 'Content Pieces'},
    component: () => import('../views/Contents.vue'),
  },
  {
    path: '/contents/add',
    name: 'Add Content Piece',
    meta: {title: 'Add Content Piece'},
    component: () => import('../views/ContentsEdit.vue'),
  },
  {
    path: '/contents/:id',
    name: 'Content Piece Detail',
    meta: {title: 'Content Library'},
    component: () => import('../views/ContentsEdit.vue'),
  },
  {
    path: '/journeys',
    name: 'Customer Journey Maps',
    meta: {title: 'Customer Journey Maps'},
    component: () => import('../views/Journeys.vue'),
  },
  {
    path: '/journeys/add',
    name: 'Add Customer Journey',
    meta: { title: 'Add Customer Journey' },
    component: () => import('../views/JourneysEdit.vue'),
  },
  {
    path: '/journeys/edit/:id',
    name: 'Edit Customer Journey',
    meta: { title: 'Edit Customer Journey' },
    component: () => import('../views/JourneysEdit.vue'),
  },
  {
    path: '/journeys/:id',
    name: 'Customer Journey Detail',
    meta: { title: 'Customer Journey Maps' },
    component: () => import('../views/Journeys.vue'),
  },
  {
    path: '/theories',
    name: 'Theories',
    meta: { title: 'Theories' },
    component: () => import('../views/Theories.vue')
  },
  {
    path: '/theories/edit/:id',
    name: 'Edit Theory',
    meta: { title: 'Edit Theory' },
    component: () => import('../views/TheoriesEdit.vue')
  },
  {
    path: '/theories/add',
    name: 'Add Theory',
    meta: { title: 'Add Theory' },
    component: () => import('../views/TheoriesEdit.vue')
  },
  {
    path: '/theories/:id',
    name: 'Theory Details',
    meta: { title: 'Theory Details' },
    component: () => import('../views/TheoryDetails.vue')
  },
  {
    path: '/audits',
    name: 'Site Audits',
    meta: {title: 'Site Audits'},
    component: () => import('../views/Audits.vue'),
  },
  {
    path: '/audits/add',
    name: 'Add Site Audit',
    meta: {title: 'Add Site Audit'},
    component: () => import('../views/AuditsAdd.vue'),
  },
  {
    path: '/audits/edit/:id',
    name: 'Edit Site Audit',
    meta: {title: 'Edit Site Audit'},
    component: () => import('../views/AuditsEdit.vue'),
  },
  {
    path: '/findings/add',
    name: 'Add Finding',
    meta: {title: 'Add Finding'},
    component: () => import('../views/FindingsEdit.vue'),
  },
  {
    path: '/findings/edit/:id',
    name: 'Edit Finding',
    meta: {title: 'Edit Finding'},
    component: () => import('../views/FindingsEdit.vue'),
  },
  {
    path: '/findings/:url',
    name: 'Node Findings',
    meta: {title: 'Node Findings'},
    component: () => import('../views/Findings.vue')
  },
  {
    path: '/findings',
    name: 'Findings Pool',
    meta: {title: 'Findings Pool'},
    component: () => import('../views/Findings.vue')
  },
  {
    path: '/hypotheses',
    name: 'Hypotheses',
    meta: { title: 'Hypotheses' },
    component: () => import('../views/Hypotheses.vue')
  },
  {
    path: '/ideas',
    name: 'Idea Backlog',
    meta: {title: 'Idea Backlog'},
    component: () => import('../views/Ideas.vue')
  },
  {
    path: '/ideas/add',
    name: 'Add Idea',
    meta: {title: 'Add Idea'},
    component: () => import('../views/IdeasEdit.vue'),
  },
  {
    path: '/ideas/edit/:id',
    name: 'Edit Idea',
    meta: {title: 'Edit Idea'},
    component: () => import('../views/IdeasEdit.vue'),
  },
  {
    path: '/ideas/:id',
    name: 'Show Idea',
    meta: {title: 'Idea Detail'},
    component: () => import('../views/Ideas.vue'),
  },
  {
    path: '/experiments',
    name: 'Experiments',
    meta: { title: 'Experiments' },
    component: () => import('../views/Experiments.vue')
  },
  {
    path: '/experiments/add',
    name: 'Edit Experiment',
    meta: { title: 'Edit Experiment' },
    component: () => import('../views/ExperimentsAdd.vue')
  },
  {
    path: '/experiments/edit/:id',
    name: 'Add Experiment',
    meta: { title: 'Add Experiment' },
    component: () => import('../views/ExperimentsAdd.vue')
  },
  {
    path: '/learnings',
    name: 'Learnings',
    meta: { title: 'Learnings' },
    component: () => import('../views/Learnings.vue')
  },
  {
    path: '/calculations',
    name: 'Success Dashboard',
    meta: { title: 'Success Dashboard' },
    component: () => import('../views/Calculations.vue')
  },
  {
    path: '/roadmaps',
    name: 'Roadmap',
    meta: {title: 'Roadmap'},
    component: () => import('../views/Roadmaps.vue')
  },
  {
    path: '/roadmaps/add',
    name: 'Add Roadmap Slot',
    meta: {title: 'Add Roadmap Slot'},
    component: () => import('../views/RoadmapsAdd.vue')
  },
  {
    path: '/channels',
    name: 'Traffic Channels',
    meta: {title: 'Traffic Channels'},
    component: () => import('../views/Channels.vue')
  },
  {
    path: '/kpis',
    name: 'KPI Tool',
    meta: { title: 'KPI Tool' },
    component: () => import('../views/Kpis.vue')
  },
  {
    path: '/campaigns',
    name: 'Campaign Planner',
    meta: { title: 'Campaign Planner' },
    component: () => import('../views/Campaigns.vue')
  },
  {
    path: '/benchmarks',
    name: 'Competition Benchmarks',
    meta: { title: 'Competition Benchmarks' },
    component: () => import('../views/Benchmarks.vue')
  },
  {
    path: '/todos',
    name: 'To Do',
    meta: { title: 'To Do' },
    component: () => import('../views/Todos.vue')
  },
  {
    path: '/notes',
    name: 'Notes',
    meta: { title: 'Notes' },
    component: () => import('../views/Notes.vue')
  },
  {
    path: '/notes/add',
    name: 'Add Note',
    meta: { title: 'Add Note' },
    component: () => import('../views/NotesEdit.vue'),
  },
  {
    path: '/notes/edit/:id',
    name: 'Edit Note',
    meta: { title: 'Edit Note' },
    component: () => import('../views/NotesEdit.vue'),
  },
  {
    path: '/patterns',
    name: 'Behaviour Patterns',
    meta: { title: 'Behaviour Patterns' },
    component: () => import('../views/Patterns.vue')
  },
  {
    path: '/practices',
    name: 'Best Practices',
    meta: { title: 'Best Practices' },
    component: () => import('../views/Practices.vue')
  },
  /*{
    path: '/personalisations',
    name: 'Personalisations',
    meta: { title: 'Personalisations' },
    component: () => import('../views/Personalisations.vue')
  },
  {
    path: '/styleguides',
    name: 'Styleguide Elements',
    meta: { title: 'Styleguide Elements' },
    component: () => import('../views/Styleguides.vue')
  },*/
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + " | Web Strategy Toolbox";

  let unprotectedRoutes = [
    '/',
    '/guide',
    '/login',
    '/signup',
    '/overview'
  ];
  let antiprotectedRoutes = [
    '/login',
    '/signup',
  ];

  let lastLogin = localStorage.getItem("lastLogin");
  let loggedIn = lastLogin ? Date.parse(lastLogin) > Date.now() - 1000 * 60 * 60 * 4 : false;

  if (loggedIn || unprotectedRoutes.find(r => r === to.path)) {
    next();
  } else if (loggedIn && antiprotectedRoutes.find(r => r === to.path)) {
    next('/logout');
  } else {
    next('/login');
  }
})

export default router
