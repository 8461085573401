<template>
  <div id="guide-container">
    <h2><i class="fad fa-fw fa-user-md-chat"></i> Web Strategy Toolbox Guide</h2>
    <p class="lead">
      Check out what makes a good Web Strategy and get Tips, Tricks, Methods and Knowledge fed to you just when you
      need it.
      <br>
      This is also a comprehensive Guide to this Toolbox and what you can do with it to level up your Website and
      Business Performance.
    </p>

    <!--<p>Todo:</p>
    <ul>
      <li>Further categorize Items</li>
      <li>Make more Content</li>
      <li>Connect final Items to content and tools</li>
      <li>Generate i18n</li>
    </ul>-->

    <div class="container mt-4 py-3 px-5 rounded bg-light scroll-container-v" style="max-height: 40rem;">
      <div class="row">
        <div v-if="guideTrace.length >= 1" class="px-3 pt-3 my-3 col-auto rounded mr-auto bg-dark text-white">
          <h3 class="h5 mb-3">{{ guide.children[0].title }}</h3>
        </div>
        <template v-for="(trace, index) in guideTrace.slice(1, guideTrace.length)">
          <div @click="guideTrace.splice(index + 1, guideTrace.length - (index + 1))" class="px-3 pt-3 my-2 col-auto rounded cursor-pointer" :class="index % 2 === 0 ? 'ml-auto bg-white' : 'mr-auto bg-dark text-white'" :key="'t' + index">
            <p class="h6 mb-3">{{ trace.title }}</p>
          </div>
          <div class="w-100" :key="'d' + index"></div>
        </template>

        <div class="p-3 my-1 col-auto rounded" :class="guideTrace.length % 2 === 0 ? 'mr-auto bg-dark text-white' : 'ml-auto bg-white'">
          <p v-if="guideTrace.length === 1" class="lead">Such dir zuerst ein Thema aus:</p>
          <p v-else-if="guideTrace.length % 2 === 0" class="lead">Über was würdest du gerne mehr erfahren?</p>
          <p v-else class="lead">Diese Themen habe ich zur Auswahl:</p>
          <div v-for="currentLevel in guideTrace[guideTrace.length-1].children" @click="guideTrace.push(currentLevel)" class="p-2 my-1 rounded cursor-pointer bg-white text-dark" :key="currentLevel.id">
            <p class="m-0"><i class="fad fa-arrow-circle-right mx-2"></i> {{ currentLevel.title }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Guide',
  components: {},
  data() {
    return {
      guideTrace: [],
      newGuide: [],
      structureImport: "",
      structureTemp: []
    }
  },
  computed: {
    guide() {
      return this.$store.state.guide;
    }
  },
  methods: {
  },
  beforeMount() {
    this.guideTrace.push(this.guide.children[0]);
  }
}
</script>
