<template>
  <div id="logout-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead mb-5">
      {{ activeRoute.description }}
    </p>

    <div class="row justify-content-center">
      <div class="col-sm-10 col-md-8 col-lg-5">
        <h3 class="text-center">Willst du dich ausloggen?</h3>

        <hr>

        <div v-if="success" class="row align-items-center my-4">
          <div class="col-auto text-center p-0">
            <i class="fad fa-check-circle fa-2x text-success ml-4"></i>
          </div>
          <div class="col-auto">
            <p class="text-left mb-1">
              <strong>Super.</strong><br>
              Du wirst jeden Moment weitergeleitet.
            </p>
          </div>
        </div>

        <button v-else class="btn btn-primary btn-block mt-3 mb-5" @click="logout">Logout</button>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Logout',
  components: {},
  data() {
    return {
      success: false
    }
  },
  computed: {},
  watch: {},
  methods: {
    logout() {
      this.success = true;
      this.$store.dispatch('auth/logOut')
          .then(() => {
            setTimeout(() => {
              this.$router.push("/login");
            }, 1000);
          });
    }
  },
  beforeMount() {
  }
}
</script>
